import compact from 'lodash/compact';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import {change, getFormInitialValues, reset} from 'redux-form';

import * as appActions from 'store/actions';
import {handleError} from 'store/actions/common';

import Load from 'core/entities/Load/types';
import loadApiGateway from 'core/gateways/LoadApiGateway';
import {
    addLoadNotes as addLoadNotesUseCase,
    createLoad as createLoadUseCase,
    getLoad as getLoadUseCase,
    updateLoad as updateLoadUseCase,
} from 'core/useCases/Load';
import {UploadedLoadFiles} from 'core/useCases/Load/types';
import {transformEmailsFromApi} from 'core/useCases/Load/utils';

import {FILE_IDS_TO_BE_REMOVED_KEY} from 'components/ui/Files/FileUpload/constants';

import {
    CREATE_LOAD_FORM_NAME,
    FORM_BOOKING_INFO_STEP,
    MODAL_NAMES,
    UPDATE_LOAD_FORM_NAME,
} from 'pages/Loads/constants/loadConstants';
import {LoadFormValues} from 'pages/Loads/types/formTypes';
import {
    getIsUpdatesSentToAgentEmail,
    getIsUpdatesSentToDispatcherEmail,
    getIsUpdatesSentToSalesRepEmail,
} from 'pages/Loads/utils/initialValues';

import openNewWindow from 'utils/openNewWindow';

import {FileNew} from 'types/File';

import {actionCreators} from '../../actionCreators';
import {actionCreators as actionCreatorsForModal} from '../../actionCreators/modal';
import {getLoad as getLoadSelector, getLoadCopyFields} from '../../selectors/common';
import * as modalActions from '../modalActions';

export function clearFormFields() {
    return function (dispatch, getState) {
        const {
            loads: {
                modal: {data: modalData},
            },
        } = getState();

        dispatch(reset(modalData.formName));
        dispatch(modalActions.closeModal());
        dispatch(actionCreators.changeCreateFormStep(FORM_BOOKING_INFO_STEP));
    };
}

const getUploadedFiles = (updatedLoad): UploadedLoadFiles[] => {
    const {
        files: {
            load: {
                new_rc: listUploadedRcFiles,
                new_bol: listUploadedBolFiles,
                new_pod: listUploadedPodFiles,
                new_files: listUploadedGeneralFiles,
                new_extra: listUploadedExtraFiles,
            },
        },
    } = updatedLoad;

    const allUploadedFiles = [
        {type: 'rc', items: listUploadedRcFiles || []},
        {type: 'bol', items: listUploadedBolFiles || []},
        {type: 'pod', items: listUploadedPodFiles || []},
        {type: 'files', items: listUploadedGeneralFiles || []},
        {type: 'extra', items: listUploadedExtraFiles || []},
    ].filter((data) => data.items.length > 0) as UploadedLoadFiles[];

    return allUploadedFiles;
};

const getDeletedFiles = (updatedLoad): number[] => {
    return updatedLoad?.files?.load[FILE_IDS_TO_BE_REMOVED_KEY] || [];
};

const getDeletedStopsIds = (changedLoad: Load, currentLoad: Load): string[] => {
    const changedLoadStopsIds = compact(changedLoad.stops.map((item) => item.id));
    const currentLoadStopsIds = compact(currentLoad.stops.map((item) => item.id));

    return difference(currentLoadStopsIds, changedLoadStopsIds);
};

export function getLoad(loadNumber: Load['number']) {
    return async function (dispatch) {
        if (!loadNumber) {
            return null;
        }
        dispatch(actionCreators.currentLoadStartLoading());
        dispatch(appActions.showLoader());
        try {
            const {load} = await getLoadUseCase({loadNumber});
            dispatch(actionCreators.fetchLoad({load}));
            dispatch(actionCreators.currentLoadStopLoading());
        } catch (error) {
            dispatch(handleError(error));
        }
        dispatch(appActions.hideLoader());
    };
}

export const createLoad = (formFields: LoadFormValues) => async (dispatch) => {
    try {
        dispatch(appActions.showLoader());

        const loadData = {
            ...omit(formFields, ['files']),
            sourceBoard: formFields?.sourceBoard?.value || null,
            bookedBySalesRepId: formFields?.bookedBySalesRep?.value.id || null,
            bookedByDispatcherId: formFields?.bookedByDispatcher?.value.id || null,
        };

        const {createdLoad, loadNumber, hasAccessToView} = await createLoadUseCase({
            loadData,
            addedFiles: getUploadedFiles(formFields),
        });

        const successModalAction = actionCreatorsForModal.showModal({
            modalName: MODAL_NAMES.successfulCreateModal,
            modalData: {loadNumber, hasAccessToView, load: createdLoad},
        });

        dispatch(actionCreators.createLoad());
        dispatch(successModalAction);
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const sendCommoditiesChanges = (initialValues: LoadFormValues, values: LoadFormValues) => () => {
    const {stops: initialStops} = initialValues;
    const {stops} = values;
    const updatedCommodities: string[] = [];

    stops.forEach((stop, index) => {
        const initialStopData = initialStops[index];

        if (stop?.commodity && initialStopData?.commodity !== stop.commodity) {
            updatedCommodities.push(stop.commodity);
        }
    });

    if (updatedCommodities.length) {
        loadApiGateway.sendUpdatedCommodities(updatedCommodities);
    }
};

export const updateLoad = (changedLoad: LoadFormValues) => async (dispatch, getState) => {
    const state = getState();
    const currentLoad = getLoadSelector(state) as Load;
    const deletedLoadStopsIds = getDeletedStopsIds(changedLoad, currentLoad);

    const formattedChangedLoad = {
        ...omit(changedLoad, ['files']),
        sourceBoard: changedLoad?.sourceBoard?.value || null,
        bookedBySalesRepId: changedLoad?.bookedBySalesRep?.value.id || null,
        bookedByDispatcherId: changedLoad?.bookedByDispatcher?.value.id || null,
    };

    try {
        dispatch(appActions.showLoader());

        const initialValues = getFormInitialValues(UPDATE_LOAD_FORM_NAME)(state) as LoadFormValues;
        dispatch(sendCommoditiesChanges(initialValues, changedLoad));

        const {updatedLoad, hasAccessToView, loadNumber} = await updateLoadUseCase({
            deletedFiles: getDeletedFiles(changedLoad),
            addedFiles: getUploadedFiles(changedLoad),
            originalLoad: currentLoad,
            changedLoad: formattedChangedLoad,
            deletedLoadStopsIds,
        });

        const successModalAction = actionCreatorsForModal.showModal({
            modalName: MODAL_NAMES.successfullyUpdatedModal,
            modalData: {load: updatedLoad, hasAccessToView, loadNumber},
        });

        dispatch(successModalAction);
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export function addLoadNote(newNote: {text: string}, loadNumber: number) {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        addLoadNotesUseCase(loadNumber, {note: newNote.text, isImportant: false})
            .then(({updatedLoad}) => {
                dispatch(actionCreators.fetchLoad({load: updatedLoad}));
            })
            .catch((error) => {
                dispatch(appActions.handleError(error));
            })
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function generateLoadBOL(loadNumber: number) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());
        try {
            const pathToGeneratedBOL = await loadApiGateway.generateLoadBol(loadNumber);

            if (pathToGeneratedBOL) {
                openNewWindow({newWindowUrl: pathToGeneratedBOL, newWindowTitle: 'BOL'});
            }
        } catch (e) {
            dispatch(appActions.handleError(e));
        } finally {
            dispatch(appActions.hideLoader());
        }
    };
}

export function generateLoadInvoice(fileFromForm: FileNew[], load: Load) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());

        try {
            const pathToGeneratedPDF = await loadApiGateway.generateInvoice(load.number, fileFromForm);

            if (pathToGeneratedPDF) {
                openNewWindow({newWindowUrl: pathToGeneratedPDF, newWindowTitle: 'INVOICE'});
            }
        } catch (e) {
            dispatch(appActions.handleError(e));
        }

        dispatch(appActions.hideLoader());
    };
}

export const initializeCreateLoadForm = () => (dispatch, getState) => {
    const state = getState();
    const loadCopyFields = getLoadCopyFields(state);

    if (!loadCopyFields) {
        return;
    }

    const changeFormField = ([key, value]) => {
        dispatch(change(CREATE_LOAD_FORM_NAME, key, value));
    };

    const {bookedBySalesRep, bookedByDispatcher} = loadCopyFields || {};

    const bookedByDispatcherField = {value: bookedByDispatcher, label: bookedByDispatcher?.fake_full_name};
    const bookedBySalesRepField = {value: bookedBySalesRep, label: bookedBySalesRep?.fake_full_name};

    const load = {
        ...loadCopyFields,
        emailsToUpdate: transformEmailsFromApi(loadCopyFields as Load),
        dispatcher_send_updates: getIsUpdatesSentToDispatcherEmail(loadCopyFields as Load),
        salesRep_send_updates: getIsUpdatesSentToSalesRepEmail(loadCopyFields as Load),
        agent_send_updates: getIsUpdatesSentToAgentEmail(loadCopyFields as Load),
        bookedByDispatcher: bookedByDispatcher ? bookedByDispatcherField : null,
        bookedBySalesRep: bookedBySalesRep ? bookedBySalesRepField : null,
    };

    Object.entries(load).forEach(changeFormField);
};
