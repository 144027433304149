import gt from 'lodash/gt';
import isEmpty from 'lodash/isEmpty';

import {checkIsDriverOwner} from 'core/entities/Driver/modules';
import Driver from 'core/entities/Driver/types';
import Owner from 'core/entities/Owner/types';
import TravelOrder from 'core/entities/TravelOrder/types';
import {TravelOrderTruckTeamMember} from 'core/entities/TravelOrder/types/TruckTeam';
import {TRUCK_TEAM_TYPES} from 'core/entities/Truck/constants/truckTeamTypes';
import {getTruckDriverIsResponsible} from 'core/entities/Truck/modules/truckDriverResponsible';
import {getTruckCoordinatorIsResponsible} from 'core/entities/Truck/modules/truckOwnerCoordinatorResponsible';
import {getTruckOwnerIsResponsible} from 'core/entities/Truck/modules/truckOwnerResponsible';
import Truck from 'core/entities/Truck/types';

import {getTravelOrderServiceProvider} from '../common/getTravelOrderServiceProvider';

export const getTruckFromTheTravelOrder = (travelOrder?: TravelOrder | null): Truck | null => {
    const serviceProvider = getTravelOrderServiceProvider(travelOrder);

    return serviceProvider?.truck || null;
};

export const isTravelOrderCreatedByAttachTruck = (travelOrder?: TravelOrder | null): boolean => {
    const serviceProvider = getTravelOrderServiceProvider(travelOrder);

    return !isEmpty(serviceProvider?.truck);
};

export const isTravelOrderHasTruck = (travelOrder?: TravelOrder | null): boolean => {
    const serviceProvider = getTravelOrderServiceProvider(travelOrder);

    return !isEmpty(serviceProvider?.truck);
};

export const isTravelOrderHasNextPlannedTO = (travelOrder: TravelOrder | null): boolean => {
    const serviceProvider = getTravelOrderServiceProvider(travelOrder);

    return gt(serviceProvider?.truck?.planned_to_count, 0);
};

export const isTravelOrderHasNextPlannedTOAfterFinish = (travelOrder: TravelOrder | null): boolean => {
    const serviceProvider = getTravelOrderServiceProvider(travelOrder);

    return Boolean(serviceProvider?.truck?.current_travel_order_number);
};

export const getTruckFirstDriverFromTheTravelOrder = (travelOrder?: TravelOrder | null): Driver | null => {
    const serviceProvider = getTravelOrderServiceProvider(travelOrder);

    return serviceProvider?.firstDriver || null;
};

export const getTruckSecondDriverFromTheTravelOrder = (travelOrder?: TravelOrder | null): Driver | null => {
    const serviceProvider = getTravelOrderServiceProvider(travelOrder);

    return serviceProvider?.secondDriver || null;
};

export const getTruckOwnerFromTheTravelOrder = (travelOrder?: TravelOrder | null): Owner | null => {
    const serviceProvider = getTravelOrderServiceProvider(travelOrder);

    return serviceProvider?.owner || null;
};

export const getTruckOwnerDriverFromTheTravelOrder = (travelOrder?: TravelOrder | null) => {
    const secondDriver = getTruckSecondDriverFromTheTravelOrder(travelOrder);
    const firstDriver = getTruckFirstDriverFromTheTravelOrder(travelOrder);

    const isSecondDriverOwner = checkIsDriverOwner(secondDriver);
    const isFirstDriverOwner = checkIsDriverOwner(firstDriver);

    if (isFirstDriverOwner) {
        return firstDriver;
    }

    if (isSecondDriverOwner) {
        return secondDriver;
    }

    return null;
};

export const getTruckOwnerCoordinatorFromTheTravelOrder = (travelOrder?: TravelOrder | null): Owner | null => {
    const serviceProvider = getTravelOrderServiceProvider(travelOrder);

    return serviceProvider?.owner?.owner_with_coordinator ? serviceProvider.owner : null;
};

export const getTravelOrderTruckTeamInfo = (travelOrder?: TravelOrder | null) => {
    const truckTeamInfo: TravelOrderTruckTeamMember[] = [];

    const ownerCoordinator = getTruckOwnerCoordinatorFromTheTravelOrder(travelOrder);
    const secondDriver = getTruckSecondDriverFromTheTravelOrder(travelOrder);
    const firstDriver = getTruckFirstDriverFromTheTravelOrder(travelOrder);
    const ownerDriver = getTruckOwnerDriverFromTheTravelOrder(travelOrder);
    const owner = getTruckOwnerFromTheTravelOrder(travelOrder);
    const truck = getTruckFromTheTravelOrder(travelOrder);

    const isFirstDriverResponsible = getTruckDriverIsResponsible(truck, firstDriver);
    const isCoordinatorResponsible = getTruckCoordinatorIsResponsible(truck);
    const isOwnerResponsible = getTruckOwnerIsResponsible(truck);

    const isFirstDriverIsAlsoOwner = checkIsDriverOwner(firstDriver);

    if (!ownerDriver && !ownerCoordinator && owner) {
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.FIRST_DRIVER,
            entity: firstDriver,
            isResponsible: Boolean(isFirstDriverResponsible),
            title: '1dr',
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER,
            entity: owner,
            isResponsible: Boolean(isOwnerResponsible),
            title: 'ow',
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.SECOND_DRIVER,
            entity: secondDriver,
            isResponsible: false,
            title: '2dr',
        });

        return truckTeamInfo;
    }

    if (!ownerDriver && ownerCoordinator && owner) {
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.FIRST_DRIVER,
            entity: firstDriver,
            isResponsible: Boolean(isFirstDriverResponsible),
            title: '1dr',
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER,
            entity: owner,
            isResponsible: Boolean(isOwnerResponsible),
            title: 'ow',
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER_COORDINATOR,
            entity: ownerCoordinator,
            isResponsible: Boolean(isCoordinatorResponsible),
            title: 'crd',
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.SECOND_DRIVER,
            entity: secondDriver,
            isResponsible: false,
            title: '2dr',
        });

        return truckTeamInfo;
    }

    if (ownerDriver && !ownerCoordinator && isFirstDriverIsAlsoOwner) {
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER_DRIVER,
            entity: owner,
            isResponsible: Boolean(isOwnerResponsible),
            title: 'o/d',
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.SECOND_DRIVER,
            entity: secondDriver,
            isResponsible: false,
            title: '2dr',
        });

        return truckTeamInfo;
    }

    if (ownerDriver && !ownerCoordinator && !isFirstDriverIsAlsoOwner) {
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER_DRIVER,
            entity: owner,
            isResponsible: Boolean(isOwnerResponsible),
            title: 'o/d',
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.FIRST_DRIVER,
            entity: firstDriver,
            isResponsible: Boolean(isFirstDriverResponsible),
            title: '1dr',
        });

        return truckTeamInfo;
    }

    if (ownerDriver && ownerCoordinator && isFirstDriverIsAlsoOwner) {
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER_DRIVER,
            entity: owner,
            isResponsible: Boolean(isOwnerResponsible),
            title: 'o/d',
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER_COORDINATOR,
            entity: ownerCoordinator,
            isResponsible: Boolean(isCoordinatorResponsible),
            title: 'crd',
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.SECOND_DRIVER,
            entity: secondDriver,
            isResponsible: false,
            title: '2dr',
        });

        return truckTeamInfo;
    }

    if (ownerDriver && ownerCoordinator && !isFirstDriverIsAlsoOwner) {
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER_DRIVER,
            entity: owner,
            isResponsible: Boolean(isOwnerResponsible),
            title: 'o/d',
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.OWNER_COORDINATOR,
            entity: ownerCoordinator,
            isResponsible: Boolean(isCoordinatorResponsible),
            title: 'crd',
        });
        truckTeamInfo.push({
            type: TRUCK_TEAM_TYPES.FIRST_DRIVER,
            entity: firstDriver,
            isResponsible: Boolean(isFirstDriverResponsible),
            title: '1dr',
        });

        return truckTeamInfo;
    }

    return [];
};
