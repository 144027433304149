import React from 'react';

import {MessageGroup} from 'widgets/Chat/types/group';

import {splitTextByTitleAndBody} from './utils';

import styles from './styles.module.scss';

type OwnProps = {
    message: MessageGroup;
};

const MessageItemNotification: React.FC<OwnProps> = (props) => {
    const {message} = props;

    const {text} = message;

    if (!text) {
        return null;
    }

    const [notificationTitle, notificationBody] = splitTextByTitleAndBody(text);

    return (
        <div className={styles.notification}>
            <div className="font-bold">{notificationTitle}</div>

            <div className={styles.notification__desc}>{notificationBody}</div>
        </div>
    );
};

export default MessageItemNotification;
