import React from 'react';

import Button from 'components/ui/Buttons/Button';
import ButtonsBlock from 'components/ui/ButtonsBlock';
import {NormalizedFile, NormalizedFileWithoutExtraData} from 'components/ui/Files/FileUpload//types';
import {ifFileHasPdfExtension, isImage} from 'components/ui/Files/FileUpload/utils';

import styles from './styles.module.scss';

type OwnProps = {
    viewInGallery?(): void;
    handleRemove?(): void;

    file: NormalizedFile | NormalizedFileWithoutExtraData;
    isFileRemovable: boolean;
    linkInNewTab?: boolean;
    gridView?: boolean;
};

const FilePreviewImage: React.FC<OwnProps> = (props: OwnProps) => {
    const {file, viewInGallery, linkInNewTab, gridView, handleRemove, isFileRemovable} = props;

    const {url: fileUrl, name: fileName} = file;

    const isPdfFile = ifFileHasPdfExtension(file);

    const openFileElement = () => {
        if (isPdfFile) {
            // eslint-disable-next-line react/jsx-no-target-blank,jsx-a11y/anchor-has-content
            return <a href={file.url} aria-label="Open Pdf" target="_blank" className="file-preview__hover" />;
        }

        return <div onClick={viewInGallery} className="file-preview__hover" />;
    };

    const checkFileType = () => {
        if (viewInGallery) {
            return isPdfFile ? window.open(file.url) : viewInGallery();
        }
    };

    if (gridView) {
        return (
            <div className={styles.grid}>
                <div className="file-preview">
                    <ButtonsBlock className="action-buttons">
                        {viewInGallery ? <Button onClick={checkFileType}>View</Button> : null}

                        {!file.isBlob ? (
                            <a className="button button-grey" href={fileUrl} download={fileName}>
                                Download
                            </a>
                        ) : null}

                        {isFileRemovable ? <Button onClick={handleRemove}>Delete</Button> : null}
                    </ButtonsBlock>

                    {openFileElement()}

                    {linkInNewTab ? (
                        <a
                            className="file-preview__link-in-new-tab"
                            rel="noopener noreferrer"
                            target="_blank"
                            href={fileUrl}
                        >
                            {' '}
                        </a>
                    ) : null}

                    {isImage(file) ? <img src={file.thumbUrl || file.url} alt="" /> : file.extension}
                </div>
            </div>
        );
    }

    const fileIcon = isImage(file) ? <img src={file.thumbUrl || file.url} alt="" /> : file.extension;

    return isPdfFile ? (
        <a
            style={{color: 'gray', textDecoration: 'none'}}
            className={styles.list}
            rel="noreferrer"
            target="_blank"
            href={file.url}
        >
            {fileIcon}
        </a>
    ) : (
        <div className={styles.list}>{fileIcon}</div>
    );
};

export default FilePreviewImage;
