import isEmpty from 'lodash/isEmpty';

import Carrier from 'core/entities/Carrier/types';
import TravelOrder from 'core/entities/TravelOrder/types';

import {getTravelOrderServiceProvider} from '../common/getTravelOrderServiceProvider';

export const getCarrierFromTheTravelOrder = (travelOrder?: TravelOrder | null): Carrier | undefined | null => {
    const serviceProvider = getTravelOrderServiceProvider(travelOrder);

    return serviceProvider?.carrier;
};

export const isTravelOrderCreatedByAttachCarrier = (travelOrder?: TravelOrder | null): boolean => {
    const serviceProvider = getTravelOrderServiceProvider(travelOrder);

    return !isEmpty(serviceProvider?.carrier);
};

export const isTravelOrderHasGlobalCarrier = (travelOrder?: TravelOrder | null): boolean => {
    const serviceProvider = getTravelOrderServiceProvider(travelOrder);

    return Boolean(serviceProvider?.carrier?.is_global);
};

export const isTravelOrderHasCarrier = (travelOrder?: TravelOrder | null): boolean => {
    const serviceProvider = getTravelOrderServiceProvider(travelOrder);

    return !isEmpty(serviceProvider?.carrier);
};
