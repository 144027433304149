import TravelOrder from 'core/entities/TravelOrder/types';

import {SORT_BY_VALUES, SORT_ORDER, TRIP_MONITOR_TABS} from 'pages/TravelOrders/constants/list';
import * as listTypes from 'pages/TravelOrders/redux/actionTypes/list';
import {handleReducerByAddBrokerUpdate} from 'pages/TravelOrders/redux/mappers/addBrokerUpdate';
import {handleReducerByReceivedEvent} from 'pages/TravelOrders/redux/mappers/addEvent';
import * as noteMappers from 'pages/TravelOrders/redux/mappers/addNote';
import {handleReducerByReceivedEventTimer} from 'pages/TravelOrders/redux/mappers/eventTimer';
import {handleReducerByReceivedSSEData} from 'pages/TravelOrders/redux/mappers/sse';
import {handleReducerBySetTripMonitorSearchParams} from 'pages/TravelOrders/redux/mappers/tripMonitor';
import {TripMonitorSearchFormValues} from 'pages/TravelOrders/types/formTypes';

import Pagination from 'types/Pagination';

type TripMonitorSorting = {
    sortBy: typeof SORT_BY_VALUES[keyof typeof SORT_BY_VALUES];
    sortOrder: typeof SORT_ORDER[keyof typeof SORT_ORDER];
};

type TripMonitorMeta = {
    late: number;
    planned: number;
    withMobileApp: number;
    inProgressAutoCheckCalls: number;
    pausedAutoCheckCalls: number;
};

export type TripMonitorState = {
    meta: Partial<TripMonitorMeta>;
    tab: typeof TRIP_MONITOR_TABS[keyof typeof TRIP_MONITOR_TABS];
    searchParams: Partial<TripMonitorSearchFormValues>;
    sorting: TripMonitorSorting;
    pagination: Pagination;
    items: TravelOrder[];
    idsSet: Set<number>;
};

const defaultState: TripMonitorState = {
    pagination: {totalItemsCount: 0, currentPage: 1, pagesCount: 0, perPage: 50},
    sorting: {sortBy: SORT_BY_VALUES.mostDelayed, sortOrder: SORT_ORDER.DESC},
    tab: TRIP_MONITOR_TABS.ALL,
    searchParams: {},
    items: [],
    idsSet: new Set([]),
    meta: {},
};

const list = (state: TripMonitorState = defaultState, action): TripMonitorState => {
    switch (action.type) {
        case listTypes.TRAVEL_ORDERS_RECEIVED: {
            return {
                ...state,
                items: action.payload.travelOrders,
                idsSet: new Set(action.payload.travelOrders.map((item) => item.number)),
            };
        }

        case listTypes.SET_TRIP_MONITOR_PAGINATION: {
            return {
                ...state,
                pagination: {...state.pagination, ...action.payload.pagination},
            };
        }

        case listTypes.SET_TRIP_MONITOR_SORT_BY: {
            return {
                ...state,
                sorting: {...state.sorting, sortBy: action.payload.sortBy},
            };
        }

        case listTypes.SET_TRIP_MONITOR_SORT_ORDER: {
            return {
                ...state,
                sorting: {...state.sorting, sortOrder: action.payload.sortOrder},
            };
        }

        case listTypes.SET_TRIP_MONITOR_TAB: {
            return {
                ...state,
                tab: action.payload.tab,
            };
        }

        case listTypes.SET_TRIP_MONITOR_SEARCH_PARAMS: {
            return handleReducerBySetTripMonitorSearchParams({
                searchParams: action.payload.searchParams,
                state,
            });
        }

        case listTypes.SET_TRIP_MONITOR_META_INFORMATION: {
            return {
                ...state,
                meta: action.payload.meta,
            };
        }

        case listTypes.TRIP_MONITOR_AUTO_EVENT_STATUS_RECEIVED: {
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    autoEventStatus: action.payload.status,
                },
            };
        }

        case listTypes.EVENT_TIMER_RECEIVED: {
            return handleReducerByReceivedEventTimer({
                travelOrderNumber: action.payload.travelOrderNumber,
                eventTimer: action.payload.eventTimer,
                state,
            });
        }

        case listTypes.CLEAR_TRIP_MONITOR_STATE: {
            return {
                ...defaultState,
                searchParams: state.searchParams,
                tab: state.tab,
                sorting: state.sorting,
            };
        }

        case listTypes.EVENT_RECEIVED: {
            const {event, travelOrderNumber, etaInfo} = action.payload;
            return handleReducerByReceivedEvent({
                travelOrderNumber,
                etaInfo,
                event,
                state,
            });
        }

        case listTypes.ADD_BROKER_UPDATE_TO_EVENT: {
            const {data, travelOrderNumber} = action.payload;

            return handleReducerByAddBrokerUpdate({travelOrderNumber, data, state});
        }

        case listTypes.RECEIVED_GENERAL_NOTE_TRAVEL_ORDER: {
            const {travelOrder} = action.payload;

            return noteMappers.handleReducerByReceivedGeneralNote({travelOrder, state});
        }

        case listTypes.RECEIVED_LOAD_NOTE_TRAVEL_ORDER: {
            const {load} = action.payload;

            return noteMappers.handleReducerByReceivedLoadNote({load, state});
        }

        case listTypes.SSE_DATA_RECEIVED: {
            const {data} = action.payload;

            return handleReducerByReceivedSSEData({data, state});
        }

        case listTypes.CLEAR_SEARCH_PARAMS: {
            return {...state, searchParams: {}};
        }

        default:
            return state;
    }
};

export default list;
