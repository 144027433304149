import React from 'react';
import {useLocation} from 'react-router-dom';
import classNames from 'classnames';

import Logo from './components/Logo';
import SidebarGroupItem from './components/SidebarGroupItem';
import SidebarSingleItem from './components/SidebarSingleItem';
import SupportItem from './components/SupportItem';
import useSidebar from './hooks/useSidebar';

import styles from './sidebar.dispatchland.module.scss';

const Sidebar: React.FC = () => {
    const {menuItems, isSidebarExpanded} = useSidebar();
    const {pathname} = useLocation();

    const menuJSX = menuItems.map((menuItem) => {
        const {name, displayName, icon, path = '', subMenu} = menuItem;

        if (subMenu) {
            return (
                <SidebarGroupItem
                    key={name}
                    currentPath={pathname}
                    isSidebarExpanded={isSidebarExpanded}
                    label={displayName}
                    subMenuItems={subMenu}
                    icon={icon}
                />
            );
        }

        return (
            <SidebarSingleItem
                key={name}
                name={name}
                icon={icon}
                pathTo={path}
                label={displayName}
                currentPath={pathname}
            />
        );
    });

    return (
        <nav className={styles.navigation}>
            <aside>
                <div id="side-bar" className={classNames('side-bar', {'side-bar-open': isSidebarExpanded})}>
                    <div id="side-bar-header" className="side-bar-header">
                        <Logo isSidebarExpanded={isSidebarExpanded} />
                    </div>

                    <ul className="side-bar-menu scroll1">{menuJSX}</ul>
                    <SupportItem />
                </div>
            </aside>
        </nav>
    );
};

export default Sidebar;
