import orderBy from 'lodash/orderBy';

import {DriversGroup} from 'widgets/Chat/types/group';

const orderByMessageDate = (groups: DriversGroup[]) => {
    const groupsWithOrderedMessages = groups.map((group) => {
        const orderedMessages = orderBy(group.messages || [], [(message) => message.createdAt || ''], ['desc']);
        return {...group, messages: orderedMessages};
    });

    return orderBy(groupsWithOrderedMessages, [(group) => group.messages[0]?.createdAt || ''], ['desc']);
};

export const sortGroups = (params) => {
    const {unreadInfo, driversGroups} = params;

    const groupWithUnreadMessages: any = [];
    const groupWithReadMessages: any = [];

    driversGroups.forEach((group) => {
        if (group.id && group.id in unreadInfo) {
            groupWithUnreadMessages.push(group);
        } else {
            groupWithReadMessages.push(group);
        }
    });

    const orderedDriversGroups = [
        ...orderByMessageDate(groupWithUnreadMessages),
        ...orderByMessageDate(groupWithReadMessages),
    ];

    return {
        orderedGroups: orderedDriversGroups,
        orderedCognitoIDs: orderedDriversGroups.map((group) => group.drivers[0].cognitoUserId),
    };
};
