import React, {Suspense, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';

import * as actions from 'store/actions';
import * as wsActions from 'store/middlewares/mainAPISocketsIO/actions';
import {checkIsUserLoggedIn} from 'store/reducers/auth/selectors';
import {selectGalleryState} from 'store/reducers/gallery/selectors';

import {loadMapApi} from 'services/mapsApi';

import Router from 'routing';

import Chat from 'widgets/Chat';

import Sidebar from 'components/layout/Sidebar';
import TopBar from 'components/layout/TopBar';
import AppLoader from 'components/ui/AppLoader';
import ModalProvider from 'components/ui/ModalProvider';
import ToastProvider from 'components/ui/ToastProvider';

import getText from 'utils/getText';
import userSession from 'utils/userSession';

import useSSE from 'hooks/useSSE';

import './assets/vendor/font-awesome-4.7.0/css/font-awesome.min.css';
import './assets/scss/_color-palette.scss';
import './assets/scss/custom.scss';
import './assets/scss/index.scss';
import './assets/scss/buttons.scss';

const ImageGallery = React.lazy(() => import('components/ui/ImageGallery'));

const App: React.FC = () => {
    const {isOpen: isGalleryOpened} = useSelector(selectGalleryState);
    const isAuthenticated = useSelector(checkIsUserLoggedIn);
    const dispatch = useDispatch();

    useEffect(() => {
        userSession.setID();

        if (isAuthenticated) {
            dispatch(actions.fetchSupportData());
            dispatch(actions.fetchCurrentCarrier());
            dispatch(actions.fetchCurrentUserAvatar());
            loadMapApi();
            dispatch(wsActions.init());
        }
    }, [isAuthenticated]);

    useSSE();

    return (
        <div className="app">
            <Helmet>
                <title>{getText('serverName')}</title>
            </Helmet>

            {isAuthenticated ? <TopBar /> : null}

            {isAuthenticated ? <Chat /> : null}

            <div className="content-wrap">
                {isAuthenticated ? <Sidebar /> : null}

                <div className="page-wrap scroll1">
                    <div className="container-fluid router-wrap">
                        <Router />
                    </div>

                    <AppLoader />
                </div>
            </div>

            <ModalProvider />

            <ToastProvider />

            {isGalleryOpened ? (
                <Suspense fallback={null}>
                    <ImageGallery />
                </Suspense>
            ) : null}
        </div>
    );
};

export default App;
