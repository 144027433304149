import React, {FC} from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';

import Button from 'components/ui/Buttons/Button/index';
import PasswordInput from 'components/ui/Form/PasswordInput/index';
import TextInput from 'components/ui/Form/TextInput';

import {validate} from 'pages/Drivers/components/modals/PasswordModal/validation';
import {FIELDS} from 'pages/Drivers/constants/fields';

const contactFields = FIELDS.contact;

type OwnProps = {
    onClose?();
};

const Form: FC<InjectedFormProps<{}, OwnProps> & OwnProps> = (props) => {
    const {handleSubmit, onClose} = props;

    return (
        <form onSubmit={handleSubmit} className="tm-confirm tm-check-in">
            <div className="modal-body__main">
                <div className="modal-body__main_item ">
                    <Field name={contactFields.email} type="text" component={TextInput} disabled={true} label="Login" />

                    <div>
                        <Field
                            name={contactFields.password}
                            type="password"
                            showValidation={true}
                            errorTooltipPlacement="top"
                            placeholder="type password with letters and numbers"
                            component={PasswordInput}
                            label="Password"
                        />

                        <Field
                            name={contactFields.confirm_password}
                            type="password"
                            errorTooltipPlacement="top"
                            showValidation={true}
                            placeholder="confirm password"
                            component={PasswordInput}
                            label="Confirm Password"
                        />
                    </div>
                </div>
            </div>
            <div className="modal-body__bottom">
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
                <div>
                    <Button type="submit" colorTheme="blue">
                        Change
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default reduxForm<{}, OwnProps>({
    validate,
})(Form);
