import React from 'react';

import useChatState from 'widgets/Chat/hooks/useChatState';
import {Driver} from 'widgets/Chat/types/group';
import {getDriverAvatar} from 'widgets/Chat/utils';

import styles from './styles.module.scss';

type OwnProps = {
    driver: Driver;
};

const DriverAvatar: React.FC<OwnProps> = (props) => {
    const {driver} = props;

    const {serverID} = useChatState();

    const imageSrc = getDriverAvatar(driver, serverID);

    const defaultDriverAvatarJSX = (
        <div className={styles.avatar}>
            <i className="fa fa-user" aria-hidden="true" />
        </div>
    );

    return (
        <div className={styles.wrap}>
            {imageSrc ? <img src={`/web/${imageSrc}`} alt={driver.name} /> : defaultDriverAvatarJSX}
        </div>
    );
};

export default DriverAvatar;
