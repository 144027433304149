import axios from 'axios';

import {store} from 'store';
import {getUserAccessToken} from 'store/reducers/auth/selectors';

import appConfig from 'config';

const chatApi = axios.create({baseURL: '/api/chat/v2'});

chatApi.interceptors.request.use(
    (config) => {
        const state = store.getState();

        const userAccessToken = getUserAccessToken(state);

        config.headers.Authorization = `Bearer ${userAccessToken}`;
        config.headers['x-server-prefix'] = appConfig.REACT_APP_PREFIX;
        config.headers['x-server-cluster'] = appConfig.REACT_APP_CLUSTER;
        config.headers['Accept-Language'] = 'en';

        return config;
    },
    (error) => Promise.reject(error),
);

chatApi.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error.response);
    },
);

export default chatApi;
