export const WS_CHAT_READ_BY_EVERY_DISPATCHER_RECEIVED = 'WS_CHAT/READ_BY_EVERY_DISPATCHER_RECEIVED' as const;
export const WS_CHAT_SEND_MESSAGE_ERROR_RECEIVED = 'WS_CHAT/SEND_MESSAGE_ERROR_RECEIVED' as const;
export const WS_CHAT_DISPATCHER_MESSAGE_RECEIVED = 'WS_CHAT/DISPATCHER_MESSAGE_RECEIVED' as const;
export const WS_CHAT_DRIVER_MESSAGE_RECEIVED = 'WS_CHAT/DRIVER_MESSAGE_RECEIVED' as const;
export const WS_CHAT_SEND_MESSAGE = 'WS_CHAT/SEND_MESSAGE' as const;

export const WS_CHAT_JOIN_SERVER_BY_DISPATCHER = 'WS_CHAT/JOIN_SERVER_BY_DISPATCHER' as const;
export const WS_CHAT_DISCONNECTION_RECEIVED = 'WS_CHAT/DISCONNECTION_RECEIVED' as const;
export const WS_CHAT_CONNECTION_RECEIVED = 'WS_CHAT/CONNECTION_RECEIVED' as const;
export const WS_CHAT_SOCKET_ID_CLEARED = 'WS_CHAT/SOCKET_ID_CLEARED' as const;
export const WS_CHAT_SET_SOCKET_ID = 'WS_CHAT/SET_SOCKET_ID' as const;
