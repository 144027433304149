import React, {useEffect} from 'react';

import ChatAlert from './components/ChatAlert';
import DriverSearchForm from './components/DriverSearchForm';
import DriversGroupExpanded from './components/DriversGroupExpanded';
import DriversGroups from './components/DriversGroups';
import useChatActions from './hooks/useChatActions';
import useChatState from './hooks/useChatState';
import {DriverSearchFormValues} from './types/form';

import styles from './styles.module.scss';

const Chat = () => {
    const {init, searchDriverByTruckNumber, getDriversGroups} = useChatActions();
    const {isChatOpen, searchParams} = useChatState();

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (isChatOpen) {
            getDriversGroups();
        }
    }, [isChatOpen]);

    if (!isChatOpen) {
        return null;
    }

    const handleSubmit = (formValues: Partial<DriverSearchFormValues>) => {
        searchDriverByTruckNumber({formValues});
    };

    return (
        <div className={styles.wrap}>
            <DriversGroupExpanded />

            <div className={styles.title}>Driver’s Chat</div>

            <ChatAlert />

            <DriverSearchForm onSubmit={handleSubmit} initialValues={{truckNumber: searchParams?.truckNumber}} />

            <DriversGroups />
        </div>
    );
};

export default Chat;
