import keyBy from 'lodash/keyBy';

import {UnreadInfo} from 'widgets/Chat/types';

import {ChatState} from '../reducers';

export * from './message';
export * from './group';

export const handleReducerByUnreadInfoReceived = (params: {state: ChatState; unreadInfo: UnreadInfo[]}) => {
    const {state, unreadInfo} = params;

    const totalUnreadMessages = unreadInfo.reduce((acc, value) => acc + value.unreadMessagesByDispatcher.length, 0);

    return {
        ...state,
        unreadInfo: keyBy(unreadInfo, 'groupID'),
        unreadMessagesCount: totalUnreadMessages,
    };
};
