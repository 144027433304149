import axios from 'axios';
import curry from 'lodash/curry';
import isEmpty from 'lodash/isEmpty';

import {store} from 'store';
import {logoutWithReload} from 'store/actions';
import {checkIsUserLoggedIn, getUserAccessToken} from 'store/reducers/auth/selectors';

import appConfig from 'config';

import {HTTP_UNAUTHORIZED} from './constants';

const restApi = axios.create({baseURL: '/api'});

const simpleApiCall = axios.create({baseURL: '/api'});

const logoutApi = axios.create({baseURL: ''});

const LOGOUT_REQUEST_URL = '/user/logout';

const getLocalStorageUserAuthKey = () => {
    const newAppBuildId = appConfig.BUILD_HASH;

    const key = `persist:appStore${newAppBuildId}`;

    try {
        const appStore = localStorage.getItem(key);

        if (!appStore) {
            console.error('App store is undefined');
            return '';
        }

        const {auth} = JSON.parse(appStore) || {};
        const {token} = JSON.parse(auth) || {};

        return token || '';
    } catch (error) {
        console.warn(`token extracting from localStorage error: ${error}`);

        return '';
    }
};

simpleApiCall.interceptors.request.use(
    (config) => {
        config.headers['Accept-Language'] = 'en';

        return config;
    },
    (error) => Promise.reject(error),
);

logoutApi.interceptors.request.use(
    (config) => {
        config.headers['Accept-Language'] = 'en';

        return config;
    },
    (error) => Promise.reject(error),
);

restApi.interceptors.request.use(
    (config) => {
        const state = store.getState();

        const userAuthKeyLocalStorage = getLocalStorageUserAuthKey();
        const userAuthKeyState = getUserAccessToken(state);

        const wsSocketId = state?.sessionData?.wsSocketId;

        config.headers.Authorization = `Bearer ${userAuthKeyLocalStorage || userAuthKeyState}`;
        config.headers['Ws-Socket-Id'] = wsSocketId;
        config.headers['Accept-Language'] = 'en';

        return config;
    },
    (error) => Promise.reject(error),
);

restApi.interceptors.response.use(
    (response) => response,
    (error) => {
        const isUserLoggedIn = checkIsUserLoggedIn(store.getState());
        const errorRequestUrl = error.response.config.url;

        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }

        // important! in logoutWithReload we call logout request
        // so for avoid loop requests in case when we can get error in logout request we should always check request url
        if (error.response.status === HTTP_UNAUTHORIZED && isUserLoggedIn && errorRequestUrl !== LOGOUT_REQUEST_URL) {
            console.warn('unauthorized, logging out ...');
            store.dispatch(logoutWithReload());
        }

        return Promise.reject(error.response);
    },
);

export const getNewCancelTokenSource = () => axios.CancelToken.source();

export const fetchFiles = async (params) => {
    try {
        return await restApi(`/files`, {params});
    } catch (e: any) {
        return {error: e.statusText};
    }
};

export const postFiles = (files, params) => {
    const formData = new FormData();
    formData.append('id', params.id);
    formData.append('entity', params.entity);
    formData.append('types[]', params.type);

    if (!params.isSingle) {
        formData.append('is_multiple[]', params.type);
    }

    files.forEach((file) => {
        formData.append(`${params.type}[]`, file);
    });

    if (params.aliases) {
        params.aliases.forEach(({alias, fileName}) => {
            formData.append(`aliases[${fileName}]`, alias);
        });
    }

    if (params.extraData) {
        params.extraData.forEach(({extraData}, index) => {
            formData.append(`extra_data[${index}]`, JSON.stringify(extraData));
        });
    }

    return restApi.post(`files`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
};

export const deleteFiles = (ids) => {
    if (isEmpty(ids)) {
        return Promise.resolve();
    }
    const promises: Promise<any>[] = [];
    const errors: any[] = [];

    ids.forEach((id) => {
        promises.push(
            restApi
                .delete(`/files/${id}`)
                .then((res) => ({id, response: res}))
                .catch((err) => {
                    errors.push({id, response: err});
                    return {id, response: err};
                }),
        );
    });

    return Promise.all(promises).then((res) => ({data: res, errors: errors.length > 0 && errors}));
};

export const deleteFile = async (id) => {
    try {
        return await restApi.delete(`/files/${id}`);
    } catch (e: any) {
        return {error: e.statusText};
    }
};

export const fetchDispatchers = async (params): Promise<any> => {
    try {
        const {isMinRequest, ...restParams} = params;

        if (isMinRequest) {
            restParams._min = 1;
        }

        return await restApi(`/dispatchers`, {params: {...restParams}});
    } catch (e: any) {
        return {error: e.statusText};
    }
};

export const fetchDrivers = async (params) => {
    try {
        const {isMinRequest, ...restParams} = params;

        if (isMinRequest) {
            restParams._min = 1;
        }

        return await restApi(`/drivers/search`, {params: {...restParams}});
    } catch (e: any) {
        return {error: e.statusText};
    }
};

export const fetchOwners = async (params) => {
    try {
        const {isMinRequest, ...restParams} = params;

        if (isMinRequest) {
            restParams._min = 1;
        }

        return await restApi(`/owners`, {params: {...restParams}});
    } catch (e: any) {
        return {error: e.statusText};
    }
};

export const fetchOwnersForSelect = async (params) => {
    try {
        return await restApi(`/owners/all`, {params});
    } catch (e: any) {
        return {error: e.statusText};
    }
};

export const fetchCarriers = async (params) => {
    try {
        return await restApi(`/carriers`, {params});
    } catch (e: any) {
        return {error: e};
    }
};

export const fetchMessages = async (id, params) => {
    try {
        return await restApi(`/messages/driver/${id}`, {params});
    } catch (e: any) {
        return {error: e.statusText};
    }
};

export const fetchUserById = (id) => restApi(`/dispatchers/${id}`);

export const postSmsMessages = (messages) => {
    return restApi.post('/messages', messages);
};

const fetchOnlyWithBearer = curry((url, token) =>
    simpleApiCall.get(url, {headers: {Authorization: `Bearer ${token}`}}),
);

export const fetchGranted = fetchOnlyWithBearer('dispatchers/is-granted');
export const fetchCurrentDispatcher = fetchOnlyWithBearer('dispatchers/me');
export const fetchStartSettings = fetchOnlyWithBearer('app-settings/start-settings');

export const logout = (token) => logoutApi.get(LOGOUT_REQUEST_URL, {headers: {Authorization: `Bearer ${token}`}});

export default restApi;
