import React from 'react';
import {NavLink} from 'react-router-dom';
import classNames from 'classnames';

import {collectAnalytics} from 'services/analyticsApi';

type OwnProps = {
    pathTo: string;
    currentPath: string;
    icon: string;
    label: string;
    name: string;
};

const SidebarSingleItem: React.FC<OwnProps> = ({name, pathTo, currentPath, icon, label}) => {
    const sendAnalytics = (): void => {
        collectAnalytics(`main-menu-${name}`);
    };

    return (
        <li className="sublist-item">
            <NavLink
                to={pathTo}
                className={classNames('side-bar-menu-item', {'active-page': pathTo === currentPath})}
                activeClassName="active"
                onClick={sendAnalytics}
            >
                <i className={icon} />
                <span className="side-bar-menu-label">{label}</span>
            </NavLink>
        </li>
    );
};

export default SidebarSingleItem;
