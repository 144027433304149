import {getUserAccessToken} from 'store/reducers/auth/selectors';

import appConfig from 'config';

import * as wsRequests from 'services/chatApi/websocket';

import * as wsTypes from 'widgets/Chat/redux/actionTypes/wsTypes';
import {WebsocketMessageRequest, WebsocketMessageResponse} from 'widgets/Chat/types/message';

export const wsActionCreators = {
    sendChatMessage: (payload: WebsocketMessageRequest) => ({type: wsTypes.WS_CHAT_SEND_MESSAGE, payload} as const),
    setWebSocketID: (payload: {webSocket: WebSocket}) => ({type: wsTypes.WS_CHAT_SET_SOCKET_ID, payload} as const),
    chatMessageSendError: () => ({type: wsTypes.WS_CHAT_SEND_MESSAGE_ERROR_RECEIVED} as const),
    receiveChatDisconnection: () => ({type: wsTypes.WS_CHAT_DISCONNECTION_RECEIVED}),
    clearSocketID: () => ({type: wsTypes.WS_CHAT_SOCKET_ID_CLEARED}),
    joinServerActionCreator: (payload: {meta: {token: string | null; serverPrefix: string}}) =>
        ({type: wsTypes.WS_CHAT_JOIN_SERVER_BY_DISPATCHER, payload} as const),
    receiveChatConnection: (payload: {webSocket: WebSocket}) =>
        ({type: wsTypes.WS_CHAT_CONNECTION_RECEIVED, payload} as const),
    receiveDispatcherMessage: (payload: {message: WebsocketMessageResponse}) =>
        ({type: wsTypes.WS_CHAT_DISPATCHER_MESSAGE_RECEIVED, payload} as const),
    wasReadByEveryDispatcher: (payload: {groupID: string}) =>
        ({type: wsTypes.WS_CHAT_READ_BY_EVERY_DISPATCHER_RECEIVED, payload} as const),
    receiveDriverMessage: (payload: {message: WebsocketMessageResponse}) =>
        ({type: wsTypes.WS_CHAT_DRIVER_MESSAGE_RECEIVED, payload} as const),
};

export const joinServer = () => (dispatch, getState) => {
    const state = getState();

    const token = getUserAccessToken(state);

    const wsRequestPayload = {
        meta: {
            serverCluster: appConfig.REACT_APP_CLUSTER,
            serverPrefix: appConfig.REACT_APP_PREFIX,
            token,
        },
    };

    dispatch(wsActionCreators.joinServerActionCreator(wsRequestPayload));
};

export const chatWSConnect = () => async () => {
    try {
        const webSocket = await wsRequests.chatWSConnectRequest();

        return webSocket;
    } catch (error) {
        return null;
    }
};
