import compact from 'lodash/compact';
import map from 'lodash/map';
import omit from 'lodash/omit';
import trim from 'lodash/trim';

import {Agent} from 'core/entities/Agent';
import Customer from 'core/entities/Customer/types';
import {Dispatcher} from 'core/entities/Dispatcher/types';
import Load from 'core/entities/Load/types';

import {NewNote} from 'types/NewNote';

import {CreateUpdateLoadParamsForApi, MetaData} from '../types';
import {convertToStopDataForApi, transformEmailsToApiRequest, transformGeneralNoteToApi} from '../utils';

type Option<T> = {label: string; value: T};
type UpdateDynamicFields = {
    createdByDispatcher: Dispatcher;
    bookedByDispatcher: Dispatcher;
    bookedBySalesRep: Dispatcher;
    bookedWithCustomer: Customer;
    agent: Agent;
    dispatchersAssignToLoad: Dispatcher[];
    createdAt: string;
    notes: NewNote[];
    note: string;
    dispatcher_send_updates: boolean;
    agent_send_updates: boolean;
};

export default function ({
    load,
    currentTravelOrders,
    addLoadFilesMeta: {files},
    deletedLoadFilesIds,
    deletedLoadStopsIds,
}: {
    load: Load;
    currentTravelOrders: Load['travelOrders'];
    addLoadFilesMeta: {files: MetaData[]};
    deletedLoadFilesIds?: number[];
    deletedLoadStopsIds?: string[];
}): CreateUpdateLoadParamsForApi {
    const dynamicFields: (keyof UpdateDynamicFields)[] = [
        'bookedBySalesRep',
        'bookedByDispatcher',
        'bookedWithCustomer',
        'createdByDispatcher',
        'createdAt',
        'dispatchersAssignToLoad',
        'notes',
        'note',
        'agent',
        'dispatcher_send_updates',
        'agent_send_updates',
    ];

    const loadData = omit(load, dynamicFields) as Load;
    const dispatcherIdsAssignToLoad = load.dispatchersAssignToLoad || [];
    const note = transformGeneralNoteToApi(load?.note);
    const bookingRepOption = load.bookedByDispatcher as unknown;
    const salesRepOption = load.bookedBySalesRep as unknown;
    const bookedByDispatcherId = (bookingRepOption as Option<Dispatcher>).value.id;
    const bookedBySalesRepId = (salesRepOption as Option<Dispatcher>)?.value?.id;

    return {
        ...loadData,
        files,
        bookedBySalesRepId,
        bookedByDispatcherId,
        deleteFileIds: deletedLoadFilesIds,
        bookedWithCustomerId: load.bookedWithCustomer.id,
        ...(load.agent ? {agentId: load.agent.id} : {}),
        dispatcherIdsAssignToLoad,
        stops: load.stops.map(convertToStopDataForApi),
        deletedStopsId: deletedLoadStopsIds,
        travelOrders: currentTravelOrders,
        note,
        refNumbers: map(compact(loadData.refNumbers), trim),
        emailsToUpdate: transformEmailsToApiRequest(load),
        trailerTypes: loadData.trailerTypes?.length ? loadData.trailerTypes : null,
        truckEquipment: loadData.truckEquipment?.length ? loadData.truckEquipment : null,
    };
}
