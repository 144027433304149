import isNumber from 'lodash/isNumber';

import {convertDate} from 'utils/dateTime';

import {AddCheckCallFormValues} from '../../types';

type ForBroker = {
    loadNumber: number | null;
    longitude: number | null;
    latitude: number | null;
};

type SetConfirmData = {
    forBroker: ForBroker[] | null;
    longitude: number | null;
    eventDate: string | null;
    latitude: number | null;
    loadNumbers: number[];
    timeZone: string;
};

export const transformSetConfirmDataToRequestBody = (createFormValue: AddCheckCallFormValues): SetConfirmData => {
    const getTruckDateTimeToUTC = (dateTime: string): string => {
        return convertDate(dateTime, {timeZoneTo: 'utc', timeZoneFrom: createFormValue.timezone}).defaultDateTime;
    };

    const forBroker = {
        loadNumber: isNumber(createFormValue.summaryItem.loadNumber) ? createFormValue.summaryItem.loadNumber : null,
        latitude: isNumber(createFormValue.summaryItem.latitude) ? createFormValue.summaryItem.latitude : null,
        longitude: isNumber(createFormValue.summaryItem.longitude) ? createFormValue.summaryItem.longitude : null,
    };

    return {
        eventDate: createFormValue.dateTime ? getTruckDateTimeToUTC(createFormValue.dateTime) : null,
        timeZone: createFormValue.timezone,
        latitude: isNumber(createFormValue.latitude) ? createFormValue.latitude : null,
        longitude: isNumber(createFormValue.longitude) ? createFormValue.longitude : null,
        forBroker: createFormValue.summaryItem.checkLocationForBroker ? [forBroker] : null,
        loadNumbers: createFormValue.loadNumbers,
    };
};
