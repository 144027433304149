import {useDispatch} from 'react-redux';

import * as actions from 'widgets/Chat/redux/actions';

import {chatWSConnect} from '../redux/actions/wsActions';
import {DriverSearchFormValues, MessageFormValues} from '../types/form';

type UseChatActions = {
    sendMessageByDispatcherToDriversGroup: (params: {message: Partial<MessageFormValues>}) => void;
    searchDriverByTruckNumber: (params: {formValues: Partial<DriverSearchFormValues>}) => void;
    markMessagesAsReadByEveryDispatcher: (params: {groupID: number | null}) => void;
    checkIsAvailableDriversGroupByDriverID: (params: {truckNumber: string}) => any;
    getDriversGroupInitLatestMessages: (params: {cognitoUserID: string}) => void;
    getUnreadMessagesAmountByDriverID: (params: {truckNumber: string}) => any;
    sendAttachmentsByUpload: (params: {fileList: FileList | null}) => void;
    getDriversGroupMoreMessages: (params: {cognitoUserID: string}) => void;
    openDriversGroupByTruckNumber: (params: {truckNumber: string}) => void;
    getDriversGroupAllMessages: (params: {cognitoUserID: string}) => void;
    getTruckForDriversGroup: (params: {cognitoUserID: string}) => void;
    leaveDriversGroup: (params: {cognitoUserID: string}) => void;
    openDriversGroup: (params: {cognitoUserID: string}) => void;
    clearSearchedDrivers: () => void;
    getDriversGroups: () => void;
    chatWSConnect: () => void;
    toggleChat: () => void;
    init: () => void;
};

const useChatActions = (): UseChatActions => {
    const dispatch = useDispatch();

    return {
        markMessagesAsReadByEveryDispatcher: (params) => dispatch(actions.markMessagesAsReadByEveryDispatcher(params)),
        getDriversGroupInitLatestMessages: (params) => dispatch(actions.getDriversGroupInitLatestMessages(params)),
        getUnreadMessagesAmountByDriverID: (params) => dispatch(actions.getUnreadMessagesAmountByDriverID(params)),
        openDriversGroupByTruckNumber: (params) => dispatch(actions.openDriversGroupByTruckNumber(params)),
        getDriversGroupMoreMessages: (params) => dispatch(actions.getDriversGroupMoreMessages(params)),
        getDriversGroupAllMessages: (params) => dispatch(actions.getDriversGroupAllMessages(params)),
        searchDriverByTruckNumber: (params) => dispatch(actions.searchDriverByTruckNumber(params)),
        getTruckForDriversGroup: (params) => dispatch(actions.getTruckForDriversGroup(params)),
        sendAttachmentsByUpload: (params) => dispatch(actions.sendAttachmentsByUpload(params)),
        leaveDriversGroup: (params) => dispatch(actions.leaveDriversGroup(params)),
        openDriversGroup: (params) => dispatch(actions.openDriversGroup(params)),
        clearSearchedDrivers: () => dispatch(actions.clearSearchedDrivers()),
        toggleChat: () => dispatch(actions.chatActionCreators.toggleChat()),
        checkIsAvailableDriversGroupByDriverID: (params) =>
            dispatch(actions.checkIsAvailableDriversGroupByDriverID(params)),
        sendMessageByDispatcherToDriversGroup: (params) =>
            dispatch(actions.sendMessageByDispatcherToDriversGroup(params)),
        getDriversGroups: () => dispatch(actions.getDriversGroups()),
        chatWSConnect: () => dispatch(chatWSConnect()),
        init: () => dispatch(actions.init()),
    };
};

export default useChatActions;
