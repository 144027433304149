import React from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';

import {DRIVER_SEARCH_FORM} from 'widgets/Chat/constants';
import {DriverSearchFormValues} from 'widgets/Chat/types/form';

import {normalizeNumberLetterHyphenInput} from 'utils';
import {getTypeFieldNameFactory} from 'utils/typeScript';

import DriverSearchInput from './components/DriverSearchInput';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<DriverSearchFormValues>();

const DriverSearchForm: React.FC<InjectedFormProps> = (props) => {
    const {handleSubmit} = props;

    return (
        <form className={styles.wrap} onSubmit={handleSubmit}>
            <Field
                normalize={normalizeNumberLetterHyphenInput}
                component={DriverSearchInput}
                name={getName('truckNumber')}
                placeholder="truck#"
                maxLength={8}
            />
        </form>
    );
};

export default reduxForm({form: DRIVER_SEARCH_FORM, enableReinitialize: true})(DriverSearchForm);
