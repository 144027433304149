import Driver from 'core/entities/Driver/types';

export const getDriverMobilePhone = (driver?: Driver | null) => driver?.mobile_phone;

export const checkIsDriverOwner = (driver?: Driver | null) => driver?.is_owner;

export const getDriverLanguage = (driver?: Driver | null) => driver?.language;

export const checkIsDriverMain = (driver?: Driver | null) => driver?.is_main;

export const getDriverID = (driver?: Driver | null) => driver?.id;
