import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, RouteProps} from 'react-router-dom';

import {checkIsUserLoggedIn} from 'store/reducers/auth/selectors';

import {decodePath} from 'routing/utils';

import RedirectToHomepage from '../RedirectToHomepage';

const AnonymousRoute: React.FC<RouteProps> = (props) => {
    const isAuthenticated = useSelector(checkIsUserLoggedIn);

    if (!isAuthenticated) {
        return <Route {...props} />;
    }

    // decode path and redirect to it after login, if any search params
    if (props.location?.search) {
        return <Redirect to={decodePath(props.location?.search)} />;
    }

    return <RedirectToHomepage />;
};

export default AnonymousRoute;
