import React from 'react';

import {AttachmentPreview} from 'widgets/Chat/types';

import ButtonsBlock from 'components/ui/ButtonsBlock';
import {ACCEPTED_IMAGE_TYPES} from 'components/ui/Files/FileUpload/constants';

import styles from './styles.module.scss';

type OwnProps = {
    file: AttachmentPreview;
    linkInNewTab?: boolean;
    gridView?: boolean;
};

const FilePreview: React.FC<OwnProps> = (props) => {
    const {file, linkInNewTab, gridView} = props;

    const {url: fileUrl, name: fileName} = file;

    const isPdfFile = file.extension === 'pdf';
    const isImageFile = ACCEPTED_IMAGE_TYPES.includes(file.extension.toLowerCase());

    const openFileElement = () => {
        if (isPdfFile) {
            // eslint-disable-next-line react/jsx-no-target-blank,jsx-a11y/anchor-has-content
            return <a href={`/${fileUrl}`} aria-label="Open Pdf" target="_blank" className="file-preview__hover" />;
        }
    };

    if (gridView) {
        return (
            <div className={styles.grid}>
                <div className="file-preview">
                    <ButtonsBlock className="action-buttons">
                        <a className="button button-grey" href={`/${fileUrl}`} download={fileName}>
                            Download
                        </a>
                    </ButtonsBlock>

                    {openFileElement()}

                    {linkInNewTab ? (
                        <a
                            className="file-preview__link-in-new-tab"
                            rel="noopener noreferrer"
                            href={`/${fileUrl}`}
                            target="_blank"
                        >
                            {' '}
                        </a>
                    ) : null}

                    {isImageFile ? <img src={`/${fileUrl}`} alt="" /> : file.extension}
                </div>
            </div>
        );
    }

    const fileIcon = isImageFile ? <img src={`/${fileUrl}`} alt="" /> : file.extension;

    return isPdfFile ? (
        <a
            style={{color: 'gray', textDecoration: 'none'}}
            className={styles.list}
            href={`/${fileUrl}`}
            rel="noreferrer"
            target="_blank"
        >
            {fileIcon}
        </a>
    ) : (
        <div className={styles.list}>{fileIcon}</div>
    );
};

export default FilePreview;
