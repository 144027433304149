import React from 'react';
import {FormControl, FormGroup} from 'react-bootstrap';
import classNames from 'classnames';
import {WrappedFieldProps} from 'redux-form';

import useChatActions from 'widgets/Chat/hooks/useChatActions';

import Button from 'components/ui/Buttons/Button';
import getValidationState from 'components/ui/Form/getValidationState';
import OverlayTriggerCustom from 'components/ui/Form/OverlayTriggerCustom';

import styles from './styles.module.scss';

type OwnProps = {
    input: WrappedFieldProps['input'];
    meta: WrappedFieldProps['meta'];
    isClearable?: boolean;
    placeholder?: string;
    autoFocus?: boolean;
    maxLength?: number;
};

const DriverSearchInput: React.FC<OwnProps> = (props) => {
    const {isClearable = true, autoFocus = false, placeholder = '', maxLength, input, meta} = props;

    const {clearSearchedDrivers} = useChatActions();

    const isValid = getValidationState(meta);

    const clearValue = () => {
        input.onChange('');
        clearSearchedDrivers();
    };

    const inputJSX = (
        <FormControl
            disabled={meta.asyncValidating}
            placeholder={placeholder}
            autoFocus={autoFocus}
            maxLength={maxLength}
            autoComplete="off"
            {...props.input}
        />
    );

    return (
        <FormGroup className={classNames(styles.wrap, {'has-feedback has-error': !isValid})}>
            <OverlayTriggerCustom inputMeta={meta}>
                {inputJSX}

                <button className={styles.button__search} type="submit">
                    <i className="fa fa-search" aria-hidden="true" />
                </button>
            </OverlayTriggerCustom>

            {isClearable && input.value ? (
                <div className={styles.button__clear}>
                    <Button buttonIcon="times" buttonSize="icon" onClick={clearValue} />
                </div>
            ) : null}
        </FormGroup>
    );
};

export default DriverSearchInput;
