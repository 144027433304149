import React from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';

import {getCurrentUserEmail, getCurrentUserID} from 'store/reducers/userData/selectors';

import {TRUCK_STATUSES_AVAILABLE_FOR_RESERVING} from 'core/entities/Truck/constants/truckStatuses';
import {getTruckReserveInfo} from 'core/entities/Truck/modules/truckReserve';
import Truck from 'core/entities/Truck/types';

import TruckReservingTimer from 'components/common/Truck/TruckReservingTimer';
import Button from 'components/ui/Buttons/Button';

import getText from 'utils/getText';

import {IsTruckReservedContext} from 'hocs/withTruckReservingTimer/IsTruckReservedContext';
import {ReservingTimerContext} from 'hocs/withTruckReservingTimer/ReservingTimerContext';

import useShowReserveAction from './useShowReserveAction';

interface OwnProps {
    truck: Truck;
    onClick?();
    isDisabled?: boolean;
    isNeedGeneralNotes?: boolean;
    isDisableDriverLinks?: boolean;
    isNeedDispatcherEmail?: boolean;
    switchToLinkOnReserve?: boolean;
    isCrossServerReserving?: boolean;
    isDisableReservedByLink?: boolean;
    isDisableDispatcherLinks?: boolean;
    isCloseOnlyReserveModalOnSuccess?: boolean;
}

const getReserveSettings = ({
    truck,
    isTruckReserved,
    currentDispatcherID,
    isCrossServerReserving,
    currentDispatcherEmail,
}: {
    truck: Truck;
    isTruckReserved: boolean;
    currentDispatcherID?: number;
    currentDispatcherEmail?: string;
    isCrossServerReserving: boolean;
}): {buttonTitle: JSX.Element | string | null; buttonColor: string; buttonClassName: string} => {
    const {reservedById, waitingRC, reservedByEmail} = getTruckReserveInfo(truck) || {};

    const reservedByCurrent = isCrossServerReserving
        ? reservedByEmail === currentDispatcherEmail
        : reservedById === currentDispatcherID;

    const btnTitleReserved = waitingRC ? <span className="title">hold for {getText('rc')}</span> : null;
    const buttonClassName = classNames('mr5', {'hold-for-rc-btn': waitingRC});

    if (!isTruckReserved) {
        return {
            buttonTitle: 'Reserve',
            buttonColor: 'green',
            buttonClassName,
        };
    }

    if (reservedByCurrent) {
        return {
            buttonTitle: btnTitleReserved,
            buttonColor: 'blue',
            buttonClassName,
        };
    }

    return {
        buttonTitle: btnTitleReserved,
        buttonColor: 'grey',
        buttonClassName,
    };
};

const TruckReserveButton: React.FC<OwnProps> = (props) => {
    const {
        isCloseOnlyReserveModalOnSuccess = false,
        isDisableDispatcherLinks = false,
        isDisableReservedByLink = false,
        isCrossServerReserving = false,
        isNeedDispatcherEmail = false,
        switchToLinkOnReserve = true,
        isDisableDriverLinks,
        isDisabled = false,
        isNeedGeneralNotes,
        onClick,
        truck,
    } = props;

    const showReserveHandler = useShowReserveAction({
        truck,
        isNeedGeneralNotes,
        isDisableDriverLinks,
        isNeedDispatcherEmail,
        isCrossServerReserving,
        isDisableReservedByLink,
        isDisableDispatcherLinks,
        isCloseOnlyReserveModalOnSuccess,
    });
    const currentUserEmail = useSelector(getCurrentUserEmail);
    const currentUserID = useSelector(getCurrentUserID);

    const onClickHandler = () => {
        showReserveHandler();
        onClick?.();
    };

    const isTruckReserveDisabled = !TRUCK_STATUSES_AVAILABLE_FOR_RESERVING.includes(truck.status);

    if (isTruckReserveDisabled) {
        return (
            <Button className="mr5" buttonSize="small" colorTheme="grey" disabled>
                No actions
            </Button>
        );
    }

    return (
        <IsTruckReservedContext.Consumer>
            {(isReserved) => {
                if (isReserved && switchToLinkOnReserve && !isDisabled) {
                    return (
                        <span onClick={onClickHandler} className="main-link">
                            View Reservation
                        </span>
                    );
                }

                const {buttonTitle, buttonColor, buttonClassName} = getReserveSettings({
                    truck,
                    isCrossServerReserving,
                    isTruckReserved: isReserved,
                    currentDispatcherID: currentUserID,
                    currentDispatcherEmail: currentUserEmail,
                });

                return (
                    <Button
                        onClick={onClickHandler}
                        className={buttonClassName}
                        colorTheme={buttonColor}
                        disabled={isDisabled}
                        buttonSize="small"
                    >
                        {buttonTitle}
                        <ReservingTimerContext.Consumer>
                            {(timer) => <TruckReservingTimer timer={timer} />}
                        </ReservingTimerContext.Consumer>
                    </Button>
                );
            }}
        </IsTruckReservedContext.Consumer>
    );
};

export default TruckReserveButton;
