import map from 'lodash/map';
import {v4 as uuidv4} from 'uuid';

import {MessageFormValues} from 'widgets/Chat/types/form';

import {getUploadedFileExtension, getUploadedFileUrl} from 'components/ui/Files/FileUpload/utils';

export const normalizeFileList = (fileList: FileList | null): MessageFormValues['attachments'] => {
    return map(fileList, (file) => ({
        extension: getUploadedFileExtension(file),
        url: getUploadedFileUrl(file),
        name: file.name,
        type: file.type,
        size: file.size,
        isBlob: true,
        id: uuidv4(),
        file,
    }));
};
