import React from 'react';

import Button from 'components/ui/Buttons/Button';

import styles from './styles.module.scss';

type OwnProps = {
    chatClientSocketID: string | null;
    connecting: boolean;
    connect: () => void;
};

const Warning: React.FC<OwnProps> = (props) => {
    const {chatClientSocketID, connect, connecting} = props;

    if (chatClientSocketID) {
        return null;
    }

    const btnIcon = connecting ? 'spinner fa-spin' : null;
    const btnText = connecting ? 'connecting...' : 'connect';

    return (
        <div className={styles.wrap}>
            <div className={styles.info}>
                <div>Chat is disconnected.</div>
                <div>Reload the page or click the button below.</div>
            </div>

            <div>
                <Button
                    className={styles.btn}
                    disabled={connecting}
                    buttonIcon={btnIcon}
                    buttonSize="small"
                    onClick={connect}
                >
                    {btnText}
                </Button>
            </div>
        </div>
    );
};

export default Warning;
