import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, RouteProps} from 'react-router-dom';

import {checkIsUserLoggedIn} from 'store/reducers/auth/selectors';

import {PermissionsValues} from 'core/entities/Permissions';

import * as pages from 'utils/data/pages';

import {encodePath} from 'routing/utils';

import PermittedRoute from '../PermittedRoute';

type OwnProps = {
    permission: PermissionsValues;
};

const PrivateRoute: React.FC<RouteProps & OwnProps> = ({permission, ...props}) => {
    const isAuthenticated = useSelector(checkIsUserLoggedIn);

    if (isAuthenticated) {
        return <PermittedRoute {...props} permission={permission} />;
    }

    // encode previous path to save it as search params when redirect to login
    const redirectTo = {
        pathname: `/${pages.LOGIN}`,
        search: encodePath(props.location?.pathname),
    };

    return <Redirect to={redirectTo} />;
};

export default PrivateRoute;
