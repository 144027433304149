import React from 'react';

import Invoice from 'core/entities/Invoice/types';

import Tooltip from 'components/ui/Tooltip';

import useInvoiceActions from 'pages/Invoices/hooks/useInvoiceActions';

import style from './style.module.scss';

type OwnProps = {
    invoice: Invoice;
};

const NoPodBadge: React.FC<OwnProps> = (props) => {
    const {invoice} = props;

    const {openChatWithNoPodDriverWarning} = useInvoiceActions();

    const handleClick = () => openChatWithNoPodDriverWarning(invoice);

    if (invoice.has_files_on_last_delivery) {
        return null;
    }

    return (
        <Tooltip className="d-inline-block btn-tooltip" tooltipContent={<span>Click to open chat</span>}>
            <div className={`mt-1 ${style.no_pod_badge}`} onClick={handleClick}>
                no PoD
            </div>
        </Tooltip>
    );
};

export default NoPodBadge;
