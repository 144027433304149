import Truck from 'core/entities/Truck/types';

export const getTruckOwnerIsResponsible = (truck?: Truck | null): boolean => {
    if (!truck) {
        return false;
    }

    const {mainContact} = truck;

    if (!mainContact) {
        return false;
    }

    return mainContact.type === 'ow' || mainContact.type === 'o/d';
};

export const checkIsMainTruckOwnerDriver = (truck?: Truck | null): boolean => truck?.mainContact?.type === 'o/d';
