import map from 'lodash/map';
import omit from 'lodash/omit';
import toNumber from 'lodash/toNumber';

import {getUserAccessToken} from 'store/reducers/auth/selectors';

import appConfig from 'config';

import {ChatState} from 'widgets/Chat/redux/reducers';
import * as selectors from 'widgets/Chat/redux/selectors';
import {MessageFormValues} from 'widgets/Chat/types/form';
import {Driver} from 'widgets/Chat/types/group';
import {DriverToWebsocketRequest, WebsocketMessageResponse} from 'widgets/Chat/types/message';

import {sortGroups} from '../common';

export const transformFileListToRequestBody = (fileList: FileList | null) => {
    const formData = new FormData();

    map(fileList, (file) => formData.append('attachment', file));

    return formData;
};

const transformDriverToRequestBody = (driver: Driver): DriverToWebsocketRequest => {
    return {
        cognitoUserId: driver.cognitoUserId,
        id: driver.id,
    };
};

const transformAttachmentsToRequestBody = (attachments: MessageFormValues['attachments']) => {
    return map(attachments, (attachment) => attachment.id);
};

export const transformDispatcherMessageToRequestBody = (state, message: Partial<MessageFormValues>): any => {
    const openedDriversGroup = selectors.getOpenedDriversGroup(state);
    const attachments = selectors.getAttachmentsToMessage(state);
    const chatDispatcher = selectors.getChatDispatcher(state);
    const driversGroups = selectors.getDriversGroups(state);
    const token = getUserAccessToken(state);

    const driversGroup = driversGroups.byCognitoUserID[openedDriversGroup.cognitoUserID];

    const [driver] = driversGroup.drivers || [];

    return {
        meta: {
            serverCluster: appConfig.REACT_APP_CLUSTER,
            serverPrefix: appConfig.REACT_APP_PREFIX,
            token,
        },
        message: {
            attachments: transformAttachmentsToRequestBody(attachments),
            driver: transformDriverToRequestBody(driver),
            groupID: driversGroup.id || null,
            dispatcherID: chatDispatcher?.id,
            text: message.text,
        },
    };
};

export const handleReducerByInitMessagesReceived = (params: {state: ChatState; messages; cognitoUserID: string}) => {
    const {state, messages, cognitoUserID} = params;

    return {
        ...state,
        driversGroups: {
            ...state.driversGroups,
            byCognitoUserID: {
                ...state.driversGroups.byCognitoUserID,
                [cognitoUserID]: {...state.driversGroups.byCognitoUserID[cognitoUserID], messages},
            },
        },
    };
};

export const handleReducerByNewDispatcherMessageReceived = (params: {
    state: ChatState;
    message: WebsocketMessageResponse;
}) => {
    const {state, message} = params;

    const {id, groupID, driverCognitoID} = message || {};

    if (!id || !groupID || !driverCognitoID) {
        return {...state, messageStatus: 'failure'};
    }

    const existingGroup = state.driversGroups.byCognitoUserID[driverCognitoID];

    const isGroupOpenedNow = state.openedDriversGroup?.cognitoUserID === driverCognitoID;

    if (isGroupOpenedNow) {
        return {
            ...state,
            messageStatus: 'success',
            unreadInfo: omit(state.unreadInfo || {}, `${message.groupID}`),
            unreadMessagesCount:
                toNumber(state.unreadMessagesCount || 0) -
                (state.unreadInfo?.[message.groupID]?.unreadMessagesByDispatcher || []).length,
            openedDriversGroup: {
                ...state.openedDriversGroup,
                unreadMessages: [],
            },
            driversGroups: {
                ...state.driversGroups,
                byCognitoUserID: {
                    ...state.driversGroups.byCognitoUserID,
                    [driverCognitoID]: {...existingGroup, messages: [...existingGroup.messages, message]},
                },
            },
        };
    }

    const byCognitoUserID = {
        ...state.driversGroups.byCognitoUserID,
        [driverCognitoID]: {...existingGroup, messages: [message]},
    };

    const newUnreadInfo = omit(state.unreadInfo || {}, `${message.groupID}`);

    const {orderedCognitoIDs} = sortGroups({
        driversGroups: Object.values(byCognitoUserID),
        unreadInfo: newUnreadInfo,
    });

    return {
        ...state,
        messageStatus: 'success',
        unreadInfo: newUnreadInfo,
        unreadMessagesCount:
            toNumber(state.unreadMessagesCount || 0) -
            (state.unreadInfo?.[message.groupID]?.unreadMessagesByDispatcher || []).length,
        driversGroups: {
            ...state.driversGroups,
            byCognitoUserID,
            allCognitoUserIDs: orderedCognitoIDs,
        },
    };
};

export const handleReducerByNewDriverMessageReceived = (params: {
    state: ChatState;
    message: WebsocketMessageResponse;
}) => {
    const {state, message} = params;

    const {groupID, driverCognitoID} = message || {};

    const existingGroup = state.driversGroups.byCognitoUserID[driverCognitoID];

    if (!existingGroup) {
        return {
            ...state,
            unreadMessagesCount: toNumber(state.unreadMessagesCount || 0) + 1,
            unreadInfo: {
                ...state.unreadInfo,
                [groupID]: {
                    groupID,
                    unreadMessagesByDispatcher: [
                        ...(state.unreadInfo?.[groupID]?.unreadMessagesByDispatcher || []),
                        message.id,
                    ],
                },
            },
        };
    }

    const isGroupOpenNow = state.openedDriversGroup?.cognitoUserID === driverCognitoID;

    if (isGroupOpenNow) {
        return {
            ...state,
            unreadMessagesCount: toNumber(state.unreadMessagesCount || 0) + 1,
            unreadInfo: {
                ...state.unreadInfo,
                [groupID]: {
                    groupID,
                    unreadMessagesByDispatcher: [
                        ...(state.unreadInfo?.[groupID]?.unreadMessagesByDispatcher || []),
                        message.id,
                    ],
                },
            },
            openedDriversGroup: {
                ...state.openedDriversGroup,
                unreadMessages: [...state.openedDriversGroup?.unreadMessages, message.id],
            },
            driversGroups: {
                ...state.driversGroups,
                byCognitoUserID: {
                    ...state.driversGroups.byCognitoUserID,
                    [driverCognitoID]: {...existingGroup, messages: [...existingGroup.messages, message]},
                },
            },
        };
    }

    const byCognitoUserID = {
        ...state.driversGroups.byCognitoUserID,
        [driverCognitoID]: {...existingGroup, messages: [message]},
    };

    const newUnreadInfo = {
        ...state.unreadInfo,
        [groupID]: {
            groupID,
            unreadMessagesByDispatcher: [
                ...(state.unreadInfo?.[groupID]?.unreadMessagesByDispatcher || []),
                message.id,
            ],
        },
    };

    const {orderedCognitoIDs} = sortGroups({
        driversGroups: Object.values(byCognitoUserID),
        unreadInfo: newUnreadInfo,
    });

    return {
        ...state,
        unreadMessagesCount: toNumber(state.unreadMessagesCount || 0) + 1,
        unreadInfo: newUnreadInfo,
        driversGroups: {
            ...state.driversGroups,
            byCognitoUserID,
            allCognitoUserIDs: orderedCognitoIDs,
        },
    };
};

export const handleReducerByMoreMessagesReceived = (params: {state: ChatState; messages; cognitoUserID: string}) => {
    const {state, messages, cognitoUserID} = params;

    return {
        ...state,
        driversGroups: {
            ...state.driversGroups,
            byCognitoUserID: {
                ...state.driversGroups.byCognitoUserID,
                [cognitoUserID]: {
                    ...state.driversGroups.byCognitoUserID[cognitoUserID],
                    messages: [...messages, ...state.driversGroups.byCognitoUserID[cognitoUserID].messages],
                },
            },
        },
    };
};
