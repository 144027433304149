import gte from 'lodash/gte';
import some from 'lodash/some';

import {convertSizeInBytesTo} from 'utils/files/formatFileSize';

const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'];
const ACCEPTED_FILE_TYPES = ['application/pdf', 'text/plain'];
const LIMIT_50_MB = 50;

const checkIsSomeFileHasUnsupportedType = (fileList: FileList | null) => {
    return some(
        fileList,
        (file) => ![...ACCEPTED_IMAGE_TYPES, ...ACCEPTED_FILE_TYPES].some((type) => type === file?.type),
    );
};

const checkIsSomeFileSizeTooLarge = (fileList: FileList | null) => {
    return some(fileList, (file) => gte(convertSizeInBytesTo(file?.size, 'MB'), LIMIT_50_MB));
};

export const validateAttachments = (fileList: FileList | null) => {
    const isSomeFileHasUnsupportedType = checkIsSomeFileHasUnsupportedType(fileList);
    const isSomeFileGte50MB = checkIsSomeFileSizeTooLarge(fileList);

    return {
        isSomeFileHasUnsupportedType,
        isSomeFileGte50MB,
    };
};
