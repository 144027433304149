import {Driver} from '../types/group';

export const getDriverTmsEntityID = (driver: Driver, serverID: string) => {
    return driver?.additionalData?.find((item) => item.serverID === serverID)?.tmsEntityID || null;
};

export const getDriverLanguage = (driver: Driver, serverID: string) => {
    return driver?.additionalData?.find((item) => item.serverID === serverID)?.language || '';
};

export const getDriverAvatar = (driver: Driver, serverID: string) => {
    return driver?.avatars.find((item) => item.serverID === serverID)?.thumb || null;
};

export const getTruckNumberFromDriver = (driver: Driver, serverID: string) => {
    return driver?.trucks?.find((item) => item.serverID === serverID)?.number || null;
};
