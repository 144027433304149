import React from 'react';
import {Field} from 'redux-form';

import DropDownCheckbox from 'components/ui/Form/DropDownCheckbox';

import {loadTypesOptions} from './utils';

interface OwnProps {
    name: string;
}

// TODO: Fix keyboard navigation/selection
// It doesn't allow to select multiple values
const LoadTypeCheckBoxMulti: React.FC<OwnProps> = (props) => {
    const {name} = props;

    return (
        <Field
            component={DropDownCheckbox}
            valueAsArray
            name={name}
            placeholder="Load Type"
            data={loadTypesOptions}
            closeMenuOnSelect={false}
        />
    );
};

export default LoadTypeCheckBoxMulti;
