import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {WrappedFieldProps} from 'redux-form';

import useChatActions from 'widgets/Chat/hooks/useChatActions';
import {MessageFormValues} from 'widgets/Chat/types/form';
import {validateAttachments} from 'widgets/Chat/validation';

import ButtonFileInput from 'components/ui/Files/FileUpload/components/ButtonFileInput';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import AttachPreview from './components/AttachPreview';

import {normalizeFileList} from './utils';

import styles from './styles.module.scss';

type OwnProps = {
    disabled: boolean;
};

const getName = getTypeFieldNameFactory<MessageFormValues>();

const AttachmentsUpload: React.FC<OwnProps & WrappedFieldProps> = (props) => {
    const {
        input: {name: inputName},
        meta: {form},
        disabled,
    } = props;

    const attachments = useGetReduxFormValue(form, getName('attachments'));
    const changeFormValue = useChangeReduxFormValue(form);
    const {sendAttachmentsByUpload} = useChatActions();

    const handleUpload = (event: React.FormEvent<HTMLInputElement>) => {
        if (!event.currentTarget.files?.length) {
            return null;
        }

        const fileList = event.currentTarget.files;

        changeFormValue(inputName, normalizeFileList(fileList));

        const {isSomeFileGte50MB, isSomeFileHasUnsupportedType} = validateAttachments(fileList);

        if (isSomeFileHasUnsupportedType || isSomeFileGte50MB) {
            return null;
        }

        sendAttachmentsByUpload({fileList});
    };

    const renderAttachmentsPreview = (attachments || []).map((attachment) => (
        <AttachPreview key={attachment.id} file={attachment} />
    ));

    return (
        <div className={styles.upload}>
            {!isEmpty(renderAttachmentsPreview) ? (
                <>
                    <div className="attachments-list">{renderAttachmentsPreview}</div>
                    <div className="count-preview">1 of 1 file selected</div>
                </>
            ) : null}

            <ButtonFileInput
                className="button-attach"
                onChange={handleUpload}
                buttonIcon="paperclip"
                inputName={inputName}
                disabled={disabled}
                colorTheme="white"
                multiple={false}
            />
        </div>
    );
};

export default AttachmentsUpload;
