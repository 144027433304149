import filter from 'lodash/filter';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import keyBy from 'lodash/keyBy';
import last from 'lodash/last';
import map from 'lodash/map';
import omit from 'lodash/omit';
import toNumber from 'lodash/toNumber';

import {ChatState} from 'widgets/Chat/redux/reducers';
import {DriverSearchFormValues} from 'widgets/Chat/types/form';
import {DriversGroup} from 'widgets/Chat/types/group';
import {getTruckNumberFromDriver} from 'widgets/Chat/utils';

import {sortGroups} from '../common';

export const getDriverFromDriversGroupsByTruckNumber = (params: {state: ChatState; truckNumber: string}) => {
    const {state, truckNumber} = params;

    const allDrivers = flatMap(state?.driversGroups?.byCognitoUserID, 'drivers');

    const driver = find(allDrivers, (item) => {
        const driverTruckNumber = getTruckNumberFromDriver(item, state.serverID);

        return driverTruckNumber === truckNumber;
    });

    return driver;
};

export const handleReducerByDriversGroupsReceived = (params: {state: ChatState; driversGroups: DriversGroup[]}) => {
    const {state, driversGroups} = params;

    const {orderedGroups} = sortGroups({driversGroups, unreadInfo: state.unreadInfo || {}});

    const byCognitoUserID = keyBy(orderedGroups, (group) => group.drivers[0].cognitoUserId);
    const allCognitoUserIDs = orderedGroups.map((group) => group.drivers[0].cognitoUserId);

    return {
        ...state,
        driversGroups: {byCognitoUserID, allCognitoUserIDs},
    };
};

export const handleReducerByLeaveDriversGroup = (params: {state: ChatState; cognitoUserID: string}) => {
    const {state, cognitoUserID} = params;

    const {driversGroups, unreadInfo} = state || {};

    const lastGroupMessage = last(driversGroups.byCognitoUserID[cognitoUserID].messages);

    const {orderedCognitoIDs} = sortGroups({
        driversGroups: Object.values(driversGroups.byCognitoUserID),
        unreadInfo: unreadInfo || {},
    });

    return {
        ...state,
        driversGroups: {
            allCognitoUserIDs: orderedCognitoIDs,
            byCognitoUserID: {
                ...state.driversGroups.byCognitoUserID,
                [cognitoUserID]: {
                    ...state.driversGroups.byCognitoUserID[cognitoUserID],
                    messages: lastGroupMessage ? [lastGroupMessage] : [],
                },
            },
        },
        openedDriversGroup: null,
        messageTextToFill: null,
    };
};

export const handleReducerByOpenDriversGroupWithMessage = (params: {
    state;
    truckNumber: string;
    messageText: string;
}) => {
    const {state, truckNumber, messageText} = params;

    const driver = getDriverFromDriversGroupsByTruckNumber({state, truckNumber});

    if (!driver) {
        return {
            ...state,
        };
    }

    return {
        ...state,
        openedDriversGroup: {...state.openedDriversGroup, cognitoUserID: driver.cognitoUserId},
        messageTextToFill: messageText,
        isOpen: true,
    };
};

export const handleReducerBySearchParamsReceived = (params: {
    state: ChatState;
    formValues: Partial<DriverSearchFormValues>;
}) => {
    const {state, formValues} = params;

    if (!formValues.truckNumber) {
        return {
            ...state,
            driversGroups: {
                ...state.driversGroups,
                searchedCognitoUserIDs: null,
            },
            searchParams: null,
        };
    }

    const allDrivers = flatMap(state?.driversGroups?.byCognitoUserID, 'drivers');

    const filteredDrivers = filter(allDrivers, (item) => {
        const driverTruckNumber = getTruckNumberFromDriver(item, state.serverID);

        return driverTruckNumber === formValues.truckNumber;
    });

    const searchedCognitoUserIDs = map(filteredDrivers, (item) => item.cognitoUserId);

    return {
        ...state,
        driversGroups: {
            ...state.driversGroups,
            searchedCognitoUserIDs,
        },
        searchParams: {truckNumber: formValues.truckNumber},
    };
};

export const handleReducerByDriversGroupAllMessagesReceived = (params: {state: ChatState; messages}) => {
    const {state, messages} = params;

    const openedGroup = state.openedDriversGroup.cognitoUserID;

    return {
        ...state,
        driversGroups: {
            ...state.driversGroups,
            byCognitoUserID: {
                ...state.driversGroups.byCognitoUserID,
                [openedGroup]: {
                    ...state.driversGroups.byCognitoUserID[openedGroup],
                    messages,
                },
            },
        },
    };
};

export const handleReducerByMarkMessagesAsReadByEveryDispatcher = (params: {
    state: ChatState;
    groupID: number | string;
}) => {
    const {state, groupID} = params;

    const newUnreadInfo = omit(state.unreadInfo, `${groupID}`);

    const {orderedCognitoIDs} = sortGroups({
        driversGroups: Object.values(state.driversGroups.byCognitoUserID),
        unreadInfo: newUnreadInfo,
    });

    return {
        ...state,
        driversGroups: {
            ...state.driversGroups,
            allCognitoUserIDs: orderedCognitoIDs,
        },
        unreadMessagesCount:
            toNumber(state.unreadMessagesCount || 0) -
            (state.unreadInfo?.[groupID]?.unreadMessagesByDispatcher || []).length,
        openedDriversGroup: {
            ...state.openedDriversGroup,
            unreadMessages: [],
        },
        unreadInfo: newUnreadInfo,
    };
};

export const handleReducerByMarkMessagesAsReadByDispatcher = (params: {state: ChatState; groupID: number}) => {
    const {state, groupID} = params;

    return {
        ...state,
        unreadMessagesCount: Number(state.unreadMessagesCount) - state.openedDriversGroup.unreadMessages.length,
        unreadInfo: {
            ...state.unreadInfo,
            [groupID]: undefined,
        },
    };
};
