import {WS_EVENTS} from 'widgets/Chat/constants';
import * as wsActions from 'widgets/Chat/redux/actions/wsActions';
import {wsActionCreators} from 'widgets/Chat/redux/actions/wsActions';
import * as wsTypes from 'widgets/Chat/redux/actionTypes/wsTypes';

const addWebSocketListeners = ({webSocketStore, store}) => {
    if (!webSocketStore.instance) {
        return;
    }

    webSocketStore.instance.on(WS_EVENTS.NEW_MESSAGE_BY_DRIVER, (newMessage) => {
        store.dispatch(wsActionCreators.receiveDriverMessage({message: newMessage.message}));
    });

    webSocketStore.instance.on(WS_EVENTS.NEW_MESSAGE_BY_DISPATCHER, (newMessage) => {
        store.dispatch(wsActionCreators.receiveDispatcherMessage({message: newMessage.message}));
    });

    webSocketStore.instance.on(WS_EVENTS.READ_BY_EVERY_DISPATCHER, (payload) => {
        store.dispatch(wsActionCreators.wasReadByEveryDispatcher(payload));
    });

    webSocketStore.instance.on('disconnect', (reason) => {
        const isReasonServerDisconnect = reason === 'io server disconnect';
        const isReasonClientDisconnect = reason === 'io client disconnect';
        const isReasonTransportError = reason === 'transport error';

        if (isReasonServerDisconnect || isReasonClientDisconnect || isReasonTransportError) {
            webSocketStore.instance.disconnect();
            webSocketStore.instance = null;
        }

        store.dispatch(wsActionCreators.clearSocketID());

        console.info(`client has disconnected, reason: ${reason}`);
    });

    webSocketStore.instance.on('reconnect', () => {
        store.dispatch(wsActionCreators.setWebSocketID({webSocket: webSocketStore.instance}));
        store.dispatch(wsActions.joinServer());
    });
};

const sendMessage = (params) => {
    const {webSocketStore, action, store} = params;

    if (!webSocketStore.instance) {
        return;
    }

    webSocketStore.instance.emit(WS_EVENTS.NEW_MESSAGE_BY_DISPATCHER, action.payload, (res) => {
        store.dispatch(
            res.error
                ? wsActionCreators.chatMessageSendError()
                : wsActionCreators.receiveDispatcherMessage({message: res.message}),
        );
    });
};

const joinServer = (params) => {
    const {webSocketStore, action} = params;

    if (!webSocketStore.instance) {
        return;
    }

    webSocketStore.instance.emit(WS_EVENTS.JOIN_SERVER_BY_DISPATCHER, action.payload, () => {});
};

const connectWebSocket = (params) => {
    const {store, action, webSocketStore} = params;

    if (webSocketStore.instance) {
        webSocketStore.instance.disconnect();
        webSocketStore.instance = null;
    }

    if (action?.payload?.webSocket) {
        webSocketStore.instance = action.payload.webSocket;

        store.dispatch(wsActionCreators.setWebSocketID({webSocket: action.payload.webSocket}));

        addWebSocketListeners({webSocketStore, store});

        store.dispatch(wsActions.joinServer());
    }
};

const disconnectWebSocket = (params) => {
    const {store, webSocketStore} = params;

    if (webSocketStore.instance) {
        webSocketStore.instance.disconnect();
        webSocketStore.instance = null;
    }

    store.dispatch(wsActionCreators.clearSocketID());
};

const actionHandlers = {
    [wsTypes.WS_CHAT_JOIN_SERVER_BY_DISPATCHER]: (params) => joinServer(params),
    [wsTypes.WS_CHAT_SEND_MESSAGE]: (params) => sendMessage(params),

    // system actions
    [wsTypes.WS_CHAT_DISCONNECTION_RECEIVED]: (params) => disconnectWebSocket(params),
    [wsTypes.WS_CHAT_CONNECTION_RECEIVED]: (params) => connectWebSocket(params),
};

const chatWebSocketMiddleware = (store) => (next) => {
    const webSocketStore = {
        instance: null,
    };

    return (action) => {
        const actionHandler = actionHandlers[action.type];

        if (actionHandler) {
            actionHandler({store, action, webSocketStore});
        }

        return next(action);
    };
};

export default chatWebSocketMiddleware;
