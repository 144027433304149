import React from 'react';

import {getEntitySummaryLink, getEntityUpdateLink, getEntityViewLink} from 'routing/linkBuilder';

import ButtonLink from 'components/ui/Buttons/ButtonLink';

import * as entityNames from 'utils/data/entityNames';

interface LinkProps {
    [prop: string]: any;
}

const getGeneralLink = (entityName, entityData, {action, content, className, onClick, target, subPage}: LinkProps) => {
    const commonData = {
        onClick,
        entityName,
        entityData,
        linkContent: content,
        linkClassName: className,
        target,
        subPage,
    };

    if (action === 'view') {
        return getEntityViewLink(commonData);
    }

    if (action === 'update') {
        return getEntityUpdateLink(commonData);
    }

    if (action === 'summary') {
        return getEntitySummaryLink(commonData);
    }

    return null;
};

const getButtonLink = (entityName, entityData, props: LinkProps) => {
    const {action, subPage, content, className, colorTheme, buttonIcon, buttonSize, disabled, onClick} = props;
    const commonData = {
        entityName,
        entityData,
        onlyString: true,
        subPage,
    };
    let href = '';

    if (action === 'view') {
        href = getEntityViewLink(commonData) as any;
    }

    if (action === 'update') {
        href = getEntityUpdateLink(commonData) as any;
    }

    return (
        <ButtonLink
            disabled={disabled}
            href={href}
            colorTheme={colorTheme}
            buttonIcon={buttonIcon}
            buttonSize={buttonSize}
            className={className}
            onClick={onClick}
        >
            {content}
        </ButtonLink>
    );
};

const createLinkComponent = (entityName: string) => {
    const LinkWrapper = (props: LinkProps) => {
        const {disabled, content, [entityName]: entityData, type, isBold = false} = props;
        const isLink = type === 'link';

        if (!entityData) {
            return null;
        }

        const hasAccessToEntity = 'hasAccess' in entityData ? entityData.hasAccess : true;

        if (!hasAccessToEntity || (disabled && isLink)) {
            if (isBold) {
                return <strong>{content}</strong>;
            }
            return content;
        }

        if (isLink) {
            return getGeneralLink(entityName, entityData, props) as any;
        }

        return getButtonLink(entityName, entityData, props) as any;
    };

    LinkWrapper.defaultProps = {
        action: 'view',
        type: 'link',
    };

    return LinkWrapper;
};

export const TruckLink = createLinkComponent(entityNames.ENTITY_NAME_TRUCK);
export const OwnerLink = createLinkComponent(entityNames.ENTITY_NAME_OWNER);
export const DriverLink = createLinkComponent(entityNames.ENTITY_NAME_DRIVER);
export const LoadLink = createLinkComponent(entityNames.ENTITY_NAME_LOAD);
export const InvoiceLink = createLinkComponent(entityNames.ENTITY_NAME_INVOICE);
export const SettlementLink = createLinkComponent(entityNames.ENTITY_NAME_SETTLEMENT);
export const SettlementCarrierLink = createLinkComponent(entityNames.ENTITY_NAME_SETTLEMENT_CARRIER);
export const SettlementOwnerLink = createLinkComponent(entityNames.ENTITY_NAME_SETTLEMENT_OWNER);
export const TravelOrderLink = createLinkComponent(entityNames.ENTITY_NAME_TRAVEL_ORDER);
export const TripTravelOrderLink = createLinkComponent(entityNames.ENTITY_NAME_TRIP_TRAVEL_ORDER);
export const TripUpdateLink = createLinkComponent(entityNames.ENTITY_NAME_TRIP_UPDATE);
export const CarrierLink = createLinkComponent(entityNames.ENTITY_NAME_CARRIER);
export const DispatcherLink = createLinkComponent(entityNames.ENTITY_NAME_DISPATCHER);
export const CustomerLink = createLinkComponent(entityNames.ENTITY_NAME_CUSTOMER);
export const FacilityLink = createLinkComponent(entityNames.ENTITY_NAME_FACILITY);
export const FactoringCompanyLink = createLinkComponent(entityNames.ENTITY_NAME_FACTORING_COMPANY);

export const LinksMap = {
    [entityNames.ENTITY_NAME_TRUCK]: TruckLink,
    [entityNames.ENTITY_NAME_OWNER]: OwnerLink,
    [entityNames.ENTITY_NAME_DRIVER]: DriverLink,
    [entityNames.ENTITY_NAME_LOAD]: LoadLink,
    [entityNames.ENTITY_NAME_INVOICE]: InvoiceLink,
    [entityNames.ENTITY_NAME_SETTLEMENT]: SettlementLink,
    [entityNames.ENTITY_NAME_SETTLEMENT_CARRIER]: SettlementCarrierLink,
    [entityNames.ENTITY_NAME_SETTLEMENT_OWNER]: SettlementOwnerLink,
    [entityNames.ENTITY_NAME_TRAVEL_ORDER]: TravelOrderLink,
    [entityNames.ENTITY_NAME_TRIP_TRAVEL_ORDER]: TripTravelOrderLink,
    [entityNames.ENTITY_NAME_TRIP_UPDATE]: TripUpdateLink,
    [entityNames.ENTITY_NAME_CARRIER]: CarrierLink,
    [entityNames.ENTITY_NAME_DISPATCHER]: DispatcherLink,
    [entityNames.ENTITY_NAME_CUSTOMER]: CustomerLink,
    [entityNames.ENTITY_NAME_FACILITY]: FacilityLink,
    [entityNames.ENTITY_NAME_FACTORING_COMPANY]: FactoringCompanyLink,
};
