import React from 'react';

import styles from './Option.module.scss';

const Option = (props): JSX.Element => {
    const {input, label, value, innerProps} = props;

    const isSelected = (input?.value || []).includes(value);

    const handleOnClick = (e): void => {
        e.preventDefault();
        const values = input.value || [];
        const nextValue = isSelected ? values.filter((item) => item !== value) : [...values, value];

        input.onChange(nextValue);
    };

    return (
        <button {...innerProps} onClick={handleOnClick} type="button" key={label} className={styles.option}>
            <span className={styles.square}>{isSelected ? <i className="fa fa-check" /> : null}</span>
            <label>{label}</label>
        </button>
    );
};

export default Option;
