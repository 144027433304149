import {useSelector} from 'react-redux';

import * as selectors from 'store/reducers/userData/selectors';

const useCurrentDispatcher = () => ({
    currentDispatcher: useSelector(selectors.getCurrentDispatcher),
    currentDispatcherID: useSelector(selectors.getCurrentUserID),
});

export default useCurrentDispatcher;
