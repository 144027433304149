import {AppState} from 'store';
import {getCurrentUserID} from 'store/reducers/userData/selectors';

import Invoice from 'core/entities/Invoice/types';
import {getLoadInvoice} from 'core/entities/Load/modules/loadRate';
import {getCurrentTravelOrder} from 'core/entities/Load/modules/loadTravelOrder';
import Load, {LoadTravelOrder} from 'core/entities/Load/types';

import {NewNote} from 'types/NewNote';

import {CopiedLoad} from '../actions/copyActions/utils';

export const getLoad = (state: AppState): Load | undefined => state.loads.current.load;
export const getIsCurrentLoadLoading = (state: AppState): boolean => state.loads.current.isLoading;
export const getLoadFormStep = (state: AppState): string => state.loads.current.formStep;
export const getExpandedPickUpDeliveryItems = (state: AppState) => state.loads.current.expandedPickUpDeliveryItems;
export const getLoadCopyFields = (state: AppState): CopiedLoad | null => state.loads.current.loadCopyFields;

export const getLoadNotes = (state: AppState): NewNote[] | undefined => state.loads.current.load?.notes;

export const getInvoice = (state: AppState): Invoice | null => {
    const load = getLoad(state);
    const invoice = getLoadInvoice(load);

    return invoice || null;
};

export const getLoadTravelOrder = (state: AppState): LoadTravelOrder | undefined => {
    const load = getLoad(state);

    return load ? getCurrentTravelOrder(load) : undefined;
};

export const getModalName = (state: AppState): string | null => state.loads.modal.name;
export const getModalData = (state: AppState) => state.loads.modal.data;
export const getKeepOpenModalName = (state: AppState): string | null | undefined => state.loads.modal.keepOpenModalName;
export const getSelectedStatusesForSearch = (state: AppState) => state.loads.list.searchParams.statuses;
export const getLoads = (state: AppState) => state.loads.list.items;
export const getAdvancedSearchParams = (state: AppState) => state.loads.list.searchParams.advancedParams;
export const getLoadsPagination = (state: AppState) => state.loads.list.pagination;
export const getCurrentDispatcherID = getCurrentUserID;
