import React, {useState} from 'react';
import classNames from 'classnames';

import {AttachmentPreview} from 'widgets/Chat/types';

import Tooltip from 'components/ui/Tooltip';

import formatFileSize from 'utils/files/formatFileSize';

import useRefWithOutsideClickHandler from 'hooks/useRefWithOutsideClickHandler';

import FilePreview from './components/FilePreview';

type OwnProps = {
    file: AttachmentPreview;
    linkInNewTab?: boolean;
    viewButton?: boolean;
    grid?: boolean;
};

const FilePreviewWrapper: React.FC<OwnProps> = (props) => {
    const {linkInNewTab = true, grid = true, file} = props;

    const [isDropDownOpened, setIsDropDownOpened] = useState(false);
    const containerNode = useRefWithOutsideClickHandler(isDropDownOpened, setIsDropDownOpened);

    return (
        <div className={classNames('file-item', {'active-state': isDropDownOpened})} ref={containerNode}>
            <FilePreview gridView={grid} file={file} linkInNewTab={linkInNewTab} />

            <div className="file-name-tooltip" onClick={() => setIsDropDownOpened(true)}>
                <Tooltip tooltipContent={`${file.name}`} display="block">
                    <div className="file-name">{file.name}</div>
                </Tooltip>

                {file.size && <div className="file-size">{formatFileSize(file.size)}</div>}
            </div>
        </div>
    );
};

export default FilePreviewWrapper;
