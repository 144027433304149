import {isValidLength} from 'utils/validation';
import * as errorMessages from 'utils/validation/errorMessages';
import * as regexps from 'utils/validation/regexps';

export const validate = (values) => {
    const errors: {[key: string]: string} = {};
    const MIN_PASSWORD_LENGTH = 6;
    const MAX_PASSWORD_LENGTH = 16;

    if (values.password && regexps.CYRILLIC.test(values.password)) {
        errors.password = errorMessages.NO_CYRILLIC;
    } else if (
        !values.password ||
        !/[0-9]/.test(values.password) ||
        !regexps.UPPERCASE_LETTERS.test(values.password) ||
        !regexps.LOWERCASE_LETTERS.test(values.password) ||
        !isValidLength(MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH, values.password)
    ) {
        errors.password = errorMessages.PASSWORD_REQUIREMENTS;
    }

    if (!values.confirm_password || values.password !== values.confirm_password) {
        errors.confirm_password = 'Current input must be the same as password.';
    }

    return errors;
};
