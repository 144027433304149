import {useState} from 'react';

import {TRUCK_TEAM_TYPES} from 'core/entities/Truck/constants/truckTeamTypes';
import {getTruckTeamInfo} from 'core/entities/Truck/modules/truckTeamInfo';
import Truck from 'core/entities/Truck/types';

import {TruckMember} from '../types/Team';

type TruckTeamSlider = {
    currentMember: TruckMember;
    currentSlide: number;
    totalSlides: number;
    isDriverInfo: boolean;
    isOwnerInfo: boolean;
    isCoordinatorInfo: boolean;
    onSelectPrev(): void;
    onSelectNext(): void;
};

const useTruckTeamSlider = (truck: Truck | null): TruckTeamSlider | null => {
    const [currentMemberIndex, setCurrentMemberIndex] = useState(0);

    if (!truck) {
        return null;
    }

    const truckTeamList = getTruckTeamInfo(truck);

    const increaseItemIndex = currentMemberIndex === truckTeamList.length - 1 ? 0 : currentMemberIndex + 1;
    const decreaseItemIndex = currentMemberIndex === 0 ? truckTeamList.length - 1 : currentMemberIndex - 1;

    const currentMember = truckTeamList[currentMemberIndex];

    const isDriverInfo = [TRUCK_TEAM_TYPES.FIRST_DRIVER, TRUCK_TEAM_TYPES.SECOND_DRIVER].includes(currentMember?.type);
    const isOwnerInfo = [TRUCK_TEAM_TYPES.OWNER, TRUCK_TEAM_TYPES.OWNER_DRIVER].includes(currentMember?.type);
    const isCoordinatorInfo = [TRUCK_TEAM_TYPES.OWNER_COORDINATOR].includes(currentMember?.type);

    const currentSlide = currentMemberIndex + 1;
    const totalSlides = truckTeamList.length;

    const onSelectPrev = () => setCurrentMemberIndex(decreaseItemIndex);
    const onSelectNext = () => setCurrentMemberIndex(increaseItemIndex);

    return {
        currentMember,
        onSelectPrev,
        onSelectNext,
        currentSlide,
        totalSlides,
        isDriverInfo,
        isOwnerInfo,
        isCoordinatorInfo,
    };
};

export default useTruckTeamSlider;
