import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import isBoolean from 'lodash/isBoolean';
import {Field} from 'redux-form';

import {getCurrentTimeZone} from 'store/reducers/appSettings/selectors';

import BadgeLocationForBroker from 'components/common/LocationEvents/modals/CheckCallModal/common/BadgeLocationForBroker';
import {getFieldNameWithSummarySection} from 'components/common/LocationEvents/modals/CheckCallModal/utils';
import CheckBoxInput from 'components/ui/Form/CheckBoxInput';
import PassedTime from 'components/ui/PassedTime';

import {convertDate, createDate} from 'utils/dateTime';
import {transformDistance} from 'utils/distance';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import styles from './styles.module.scss';

type SummaryData = {
    isSendToBrokerSystem: boolean | null;
    distanceToRealLocation: number;
    loadNumber: number;
};

type OwnProps = {
    summaryData: SummaryData;
    formSection: string;
    form: string;
};

const SummaryInfo: React.FC<OwnProps> = (props) => {
    const {form, formSection, summaryData} = props;

    const {loadNumber, distanceToRealLocation, isSendToBrokerSystem} = summaryData || {};

    const changeFormValue = useChangeReduxFormValue(form);

    useEffect(() => {
        changeFormValue(getFieldNameWithSummarySection(formSection, 'distanceToRealLocation'), distanceToRealLocation);
        changeFormValue(getFieldNameWithSummarySection(formSection, 'isSendToBrokerSystem'), isSendToBrokerSystem);
    }, []);

    const timeZone = useSelector(getCurrentTimeZone);

    const isSendToSummary = useGetReduxFormValue(form, getFieldNameWithSummarySection(formSection, 'sendToSummary'));

    const checkLocationForBroker = useGetReduxFormValue(
        form,
        getFieldNameWithSummarySection(formSection, 'checkLocationForBroker'),
    );

    const isBehindRealEvent = useGetReduxFormValue(
        form,
        getFieldNameWithSummarySection(formSection, `behindRealEvent`),
    );

    const summaryLocationForBroker = useGetReduxFormValue(
        form,
        getFieldNameWithSummarySection(formSection, 'cityLine'),
    );

    const summaryDateTimeForBroker = useGetReduxFormValue(
        form,
        getFieldNameWithSummarySection(formSection, 'dateTime'),
    );

    const transformedDate = createDate(summaryDateTimeForBroker, {fromTimeZone: timeZone});
    const convertTruckDateTimeToUTC = convertDate(summaryDateTimeForBroker, {
        timeZoneTo: 'utc',
        timeZoneFrom: timeZone,
    });

    const convertedDistance = transformDistance(distanceToRealLocation);

    const checkMainInfo = (
        <div className={styles.main}>
            <div className={styles.location}>
                <p>
                    <strong>{summaryLocationForBroker}</strong>
                </p>
                <div className={styles.dateTime}>
                    {summaryDateTimeForBroker ? (
                        <>
                            {transformedDate.fullDate} {transformedDate.timeZoneCode}{' '}
                            <PassedTime from={convertTruckDateTimeToUTC.defaultDateTime} />
                        </>
                    ) : null}
                </div>
                <div className={classNames(styles.distance, isBehindRealEvent ? styles.behind : styles.ahead)}>
                    <strong>{convertedDistance.fromMiles}</strong> {convertedDistance.shortUnit}{' '}
                    {isBehindRealEvent ? 'behind' : 'ahead'} real event
                </div>
            </div>
            <div className={styles.check}>
                <Field
                    name={getFieldNameWithSummarySection(formSection, 'isSendToBrokerUpdate')}
                    label="Send Broker Update"
                    component={CheckBoxInput}
                    position="right"
                    className={styles.checkbox}
                />

                {isBoolean(isSendToBrokerSystem) ? (
                    <Field
                        name={getFieldNameWithSummarySection(formSection, 'isSendToBrokerSystem')}
                        label="Send to Broker’s System"
                        className={styles.checkbox}
                        component={CheckBoxInput}
                        position="right"
                    />
                ) : null}
            </div>
        </div>
    );

    const mainContent = isSendToSummary ? checkMainInfo : <em>no event</em>;

    return (
        <div className={styles.wrap}>
            <div className={styles.left}>
                <div className={styles.title}>
                    load summary #{loadNumber} event <BadgeLocationForBroker isRealLocation={!checkLocationForBroker} />
                </div>
                {mainContent}
            </div>
            <div className={styles.right} />
        </div>
    );
};

export default SummaryInfo;
